import { IVARIEDADCARGA_INIT_STATE } from '@interfaces/redux/IStorage';
import { types } from "../types/types";


const INIT_STATE: IVARIEDADCARGA_INIT_STATE = {
    variedadCargaSelected: null,
    list: [],
    selectBaseData: {
        tiposCarga: [],
    },
};
export const VariedadesCargaReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.VARIEDADCARGA_INIT: {
            return {
                ...state, 
                ...action.payload,
            };
        }
        case types.VARIEDADCARGA_SAVE_LIST: {
            return {
                ...state,
                list: action.payload,
                variedadCargaSelected: null
            };
        }
        case types.VARIEDADCARGA_SELECT_TO_EDIT: {
            return {
                ...state,
                variedadCargaSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
