import { LoadingButton } from "@mui/lab";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

import { ApiConductor } from "@api/conductor";
import { ConductoresSaveListAction } from "@redux/actions/conductoresActions";
import { vigenteListSelect } from "@helpers/genericList";
import SSelect from "@controls/inputs/SSelect";

const ConductoresCreate = () => {

    const distpatch = useDispatch();

    const form = useForm({ mode: "onChange" });

    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
    const [inRequest, setInRequest] = useState<boolean>(false);

    const { handleSubmit } = form;

    const onSubmit = (data: any) => {
        setInRequest(true);
        ApiConductor.PostConductor(data).then((resp: any) => {
            setInRequest(false);
            if (resp.data.success) {

                form.reset({});

                setResultAlert({
                    message: resp.data?.message,
                    severity: "success",
                });

                distpatch(ConductoresSaveListAction(resp.data.list));
            } else {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "error",
                });
            }
        });
    };
    return (
        <Container component="main">
            <Box>
                <Typography component="h1" variant="h4">
                    Formulario de Conductores
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="rut"
                                form={form}
                                type="text"
                                label="Rut"
                                mode="rut"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="nombre"
                                form={form}
                                type="text"
                                label="Nombre y Apellido"
                                mode='nombreyapellido'
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="correo"
                                form={form}
                                type="text"
                                label="Correo"
                                mode="email"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="telefono"
                                form={form}
                                type="text"
                                label="Teléfono"
                                mode="phone"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                             <SSelect
                                label="Contratado"
                                name="contratado"
                                form={form}
                                items={vigenteListSelect}
                                defaultValue={""}
                                rules={{
                                required: "Este campo es requerido",
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} display="flex" justifyContent="flex-end">
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                loading={inRequest}
                            >
                                Guardar conductor
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
        </Container>
    );
};
export default ConductoresCreate;
