import CentroCostosCreate from "./partial/CentroCostosCreate";
import CentroCostosList from "./partial/CentroCostosList";

const CentroCostos = () => {
    
    return (
        <div className="container">
            <CentroCostosCreate />
            <CentroCostosList />
        </div>
    );
};
export default CentroCostos;
