import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { DataGrid, GridColumns, GridRowsProp, esES } from "@mui/x-data-grid";

interface ISDataGrid {
    columns: GridColumns;
    disableSelectionOnClick?: boolean,
    rows: GridRowsProp;
    error?: any;
    loading?: boolean;
    sx?: SxProps<Theme>;
}
const SDataGrid = (props: ISDataGrid) => {
    return <DataGrid {...props} localeText={esES.components.MuiDataGrid.defaultProps.localeText} />;
};

export default SDataGrid;
