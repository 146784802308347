import { useEffect, useState } from "react";
import { Chip, Grid, InputAdornment, Typography } from "@mui/material";
import Form from "react-hook-form";
import SSelect from "@controls/inputs/SSelect";
import STextField from "@controls/inputs/STextField";
import { useAppSelector } from "@redux/store/store";
import { bodegaOrigen } from "@helpers/genericList";
import SAutoComplete from "@controls/inputs/SAutoComplete";

interface ITransportFormTransport {
    form: Form.UseFormReturn<any>;
}

const TransportFormTransport = (props: ITransportFormTransport) => {
    const { form } = props;

    const { watch, setValue } = form;

    const {
        selectBaseData: {
            conductor,
            camion,
            centrodecostos,
            empresaTransportista,
            empresaDestino,
            empresaOrigen,
            acoplado,
            direccionDestino,
            tipoTraslado,
        },
    } = useAppSelector((state) => state.transporte);

    const { acopladoId, camionId, empresadestinoId, tipotrasladoId } = watch();

    const [isSale, setIsSale] = useState<boolean>();

    useEffect(() => {
        if (!!acopladoId && !!camionId) {
            let acopladoTara = acoplado.find((a) => a.id === acopladoId);
            let camionTara = camion.find((a) => a.id === camionId);
            let temporalTara = Number(acopladoTara?.tara) + Number(camionTara?.tara);
            setValue("sumaTara", temporalTara);
        } else {
            setValue("sumaTara", "");
        }
        // eslint-disable-next-line
    }, [acopladoId, camionId]);

    useEffect(() => {
        if (!!tipotrasladoId) {
            const trasladoSelected = tipoTraslado.find(
                (t) => t.id === tipotrasladoId
            );
            setIsSale(!!trasladoSelected?.centroDeCostos);
        }
        // eslint-disable-next-line
    }, [tipotrasladoId]);

    return (
        <Grid container spacing={2} style={{ marginTop: "15px" }}>
            <Grid item xs={12}>
                <Typography component="h3" variant="h6">
                    <Chip label="1" color="primary" size="small" /> Información transporte
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <SAutoComplete
                    name="camionId"
                    form={form}
                    label="Camión"
                    items={camion}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Acoplado"
                    name="acopladoId"
                    form={form}
                    items={acoplado}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <STextField
                    name="sumaTara"
                    form={form}
                    mode="number"
                    label="Tara"
                    placeholder="Suma Tara"
                    disabled
                    rules={{}}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SSelect
                    name="empresatransportistaId"
                    form={form}
                    label="Transportista"
                    items={empresaTransportista}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Conductor"
                    name="conductorId"
                    form={form}
                    items={conductor}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Lugar Origen"
                    name="empresaorigenId"
                    form={form}
                    items={empresaOrigen}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Lugar destino"
                    name="empresadestinoId"
                    form={form}
                    items={empresaDestino}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Dirección Destino"
                    name="direcciondestinoId"
                    form={form}
                    items={direccionDestino}
                    defaultValue={""}
                    filterBy={{
                        itemKey: "empresadestinoId",
                        value: empresadestinoId,
                    }}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Tipo Traslado"
                    name="tipotrasladoId"
                    form={form}
                    items={tipoTraslado}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Centro de Costos"
                    name="centrodecostosId"
                    form={form}
                    items={centrodecostos}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Bodega origen"
                    name="codigoBodegaOrigen"
                    form={form}
                    items={bodegaOrigen}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                {!isSale && !!tipotrasladoId && (
                    <SSelect
                        label="Bodega destino"
                        name="codigoBodegaDestino"
                        form={form}
                        items={bodegaOrigen}
                        defaultValue={""}
                        rules={{
                            required: "Este campo es requerido"
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default TransportFormTransport;
