import { IDireccionDestino } from "@interfaces/models/IDireccionDestino";
import { types } from "../types/types";
import { IDIRECCIONDESTINO_INIT_STATE } from "../../interfaces/redux/IStorage";

export const DireccionInitAction = (data: IDIRECCIONDESTINO_INIT_STATE) => ({
    type: types.DIRECCION_INIT,
    payload: data
})

export const DireccionSaveListAction = (data: IDireccionDestino[]) => ({
    type: types.DIRECCION_SAVE_LIST,
    payload: data
})

export const DireccionesSelectToEditAction = (data: IDireccionDestino | null) => ({
    type: types.DIRECCION_SELECT_TO_EDIT,
    payload: data
})
