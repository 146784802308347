import { ApiPesaje } from "@api/pesaje";
import SAutoComplete from "@controls/inputs/SAutoComplete";
import SSelect from "@controls/inputs/SSelect";
import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { DevTool } from "@hookform/devtools";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Card,
    CardContent,
    Container,
    Grid,
    InputAdornment,
    Typography,
} from "@mui/material";
import { useAppSelector } from "@redux/store/store";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const PesajeCreate = () => {
    // Sacar la id usuario para el post
    const {
        userGlobalParameters: { id },
    } = useAppSelector((store) => store.global);

    const {
        selectBaseData: { conductor, camion, acoplado },
    } = useAppSelector((state) => state.transporte);

    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
    const [inRequest, setInRequest] = useState<boolean>(false);

    const form = useForm();

    const { handleSubmit, control, setValue, watch } = form;

    const { acopladoId, camionId } = watch();

    const onSubmit = (data: any) => {
        setInRequest(true);
        // Agregar id Usuario  fechaPlanificada: FechaPlanificada
        data = { ...data, usuarioId: id };
        ApiPesaje.PostPesaje(data).then((resp: any) => {
            setInRequest(false);
            if (resp.data.success) {
                form.reset({});
                setResultAlert({
                    message: resp.data?.message,
                    severity: "success",
                });
            } else {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "error",
                });
            }
        });
    };

    useEffect(() => {
        if (!!acopladoId && !!camionId) {
            let acopladoTara = acoplado.find((a) => a.id === acopladoId);
            let camionTara = camion.find((a) => a.id === camionId);
            let temporalTara = Number(acopladoTara?.tara) + Number(camionTara?.tara);
            setValue("sumaTara", temporalTara);
        } else {
            setValue("sumaTara", "");
        }
        // eslint-disable-next-line
    }, [acopladoId, camionId]);

    return (
        <>
            <DevTool control={control} />
            <Container component="main">
                <Box>
                    <Card>
                        <CardContent style={{ padding: "40px" }}>
                            <Typography component="h1" variant="h4">
                                Pesaje
                            </Typography>
                            <br />
                            <Box
                                component="form"
                                onSubmit={handleSubmit(onSubmit)}
                                sx={{ mt: 3, width: "100%" }}
                            >
                                <Grid container spacing={2}>


                                    <Grid item xs={12} sm={4}>
                                        <SAutoComplete
                                            name="camionId"
                                            form={form}
                                            label="Camión"
                                            items={camion}
                                            rules={{
                                                required: "Este campo es requerido",
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <SSelect
                                            label="Acoplado"
                                            name="acopladoId"
                                            form={form}
                                            items={acoplado}
                                            defaultValue={""}
                                            rules={{
                                                required: "Este campo es requerido",
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <STextField
                                            name="sumaTara"
                                            form={form}
                                            mode="number"
                                            label="Tara"
                                            placeholder="Suma Tara"
                                            disabled
                                            rules={{}}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">kg</InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <SSelect
                                            label="Conductor"
                                            name="conductorId"
                                            form={form}
                                            items={conductor}
                                            defaultValue={""}
                                            rules={{
                                                required: "Este campo es requerido",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="flex-end">
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        loading={inRequest}
                                    >
                                        Generar y enviar
                                    </LoadingButton>
                                </Grid>
                            </Box>
                        </CardContent>{" "}
                    </Card>
                </Box>

                <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
            </Container>
        </>
    );
};
export default PesajeCreate;
