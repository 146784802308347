import { Chip, Grid, InputAdornment, Typography } from "@mui/material";
import Form from "react-hook-form";
import SSelect from "@controls/inputs/SSelect";

import { TipoDocumento, confirmListSelect, tipoOtraMoneda } from "@helpers/genericList";
import STextField from "@controls/inputs/STextField";
import SDatePicker from "@controls/inputs/SDatePicker";
import { useEffect } from "react";
import { ConvertToDecimal } from "@helpers/number";
interface ITransportFormItem {
    form: Form.UseFormReturn<any>;
}

const TransportAditionalDetail = (props: ITransportFormItem) => {
    const { form } = props;

    const { setValue, watch } = form;

    const { otraMoneda, costoFlete, tipoCambioOtraMoneda } = watch();

    useEffect(() => {
        if (!!costoFlete && !!otraMoneda && !!tipoCambioOtraMoneda) 
        {
            const tipoCambioFloat = parseFloat(tipoCambioOtraMoneda);
            const netoOtraMoneda =  costoFlete / tipoCambioFloat;

            const ivaOtraMoneda = parseFloat(ConvertToDecimal((netoOtraMoneda * 0.19).toString()));

            setValue("netoOtraMoneda", netoOtraMoneda);
            setValue("ivaOtraMoneda", ivaOtraMoneda);
            setValue("totalOtraMoneda", netoOtraMoneda + ivaOtraMoneda);
        }
        else{
            setValue("netoOtraMoneda", "");
            setValue("ivaOtraMoneda", "");
            setValue("totalOtraMoneda", "");
        }
        // eslint-disable-next-line
    }, [costoFlete, otraMoneda, tipoCambioOtraMoneda]);

    return (
        <Grid container spacing={2} style={{ marginTop: "15px" }}>
            <Grid item xs={12}>
                <Typography component="h3" variant="h6">
                    <Chip label="4" color="secondary" size="small" /> Adicional
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Tipo documento referencia"
                    name="tipoDocRef"
                    form={form}
                    items={TipoDocumento}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <STextField
                    name="folioRef"
                    label="Folio referencia"
                    form={form}
                    placeholder="Folio referencia"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SDatePicker name="fechaRef" label="Fecha referencia" form={form} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <STextField
                    name="razonRef"
                    label="Razón referencia"
                    form={form}
                    placeholder="Razón referencia"
                />
            </Grid>
            <Grid
                container
                spacing={2}
                style={{ marginTop: "15px", marginLeft: "0px" }}
            >
                <Grid item xs={12} sm={4}>
                    <SSelect
                        label="Tiene otra moneda"
                        name="otraMoneda"
                        form={form}
                        items={confirmListSelect}
                        defaultValue={""}
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                {!!otraMoneda && (
                    <>
                        <Grid item xs={12} sm={4}>
                            <SSelect
                                name="tipoOtraMoneda"
                                label="Tipo otra moneda"
                                form={form}
                                items={tipoOtraMoneda}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="tipoCambioOtraMoneda"
                                label="Tipo Cambio moneda"
                                form={form}
                                placeholder="Tipo cambio moneda"
                                mode="decimal"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h6" variant="subtitle2">
                                <Chip
                                    variant="outlined"
                                    label="$"
                                    color="success"
                                    size="small"
                                />{" "}
                                Precio otra moneda
                            </Typography>
                        </Grid>

                        {/* Esto es total guia? */}
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="netoOtraMoneda"
                                form={form}
                                label="NETO"
                                placeholder="NETO"
                                disabled
                                mode="decimal"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="ivaOtraMoneda"
                                form={form}
                                label="IVA"
                                placeholder="IVA"
                                disabled
                                mode="decimal"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="totalOtraMoneda"
                                form={form}
                                label="TOTAL"
                                placeholder="TOTAL"
                                disabled
                                mode="decimal"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Requiere Guía"
                    name="requiereIdQr"
                    form={form}
                    items={confirmListSelect}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default TransportAditionalDetail;
