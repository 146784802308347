import Form from "react-hook-form";
import { FormHelperText, InputBaseProps, TextField, TextFieldProps } from "@mui/material";
import { TSTextFieldMode } from "@intertal_types/TSTextField";
import { TextFieldInputPropsMode, TextFieldInputVisibleValueMode, TextFielRulesMode } from "@helpers/input/STextField";

interface SInputBaseProps extends InputBaseProps{
    maxLength?: number
}

type TProps = TextFieldProps & {
    name: string;
    form: Form.UseFormReturn<any>;
    mode?: TSTextFieldMode;
    rules?: Form.RegisterOptions;
    inputProps?: SInputBaseProps['inputProps']
};

const STextField = (props: TProps) => {
    const {
        register,
        formState: { errors },
        watch,
    } = props.form;

    const value = watch(props.name);

    return (
        <>
            <TextField
                margin="normal"
                fullWidth
                id={props.name}
                error={!!errors[props.name]?.message}
                label={props.label}
                {...register(props.name, TextFielRulesMode(props.mode, props.rules))}
                value={TextFieldInputVisibleValueMode(value, props.mode) ?? ""}
                {...props}
                inputProps={TextFieldInputPropsMode(props.mode, props.inputProps)}
                sx={{padding: "0", margin: "5px",  ...props.sx}}
            />
            <FormHelperText id={props.name + "-error-text"} error>
                {errors[props.name]?.message}
            </FormHelperText>
        </>
    );
};
export default STextField;
