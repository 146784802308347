import DireccionCreate from "./partial/DireccionCreate";
import DireccionList from "./partial/DireccionList";

import LoadingPage from "@controls/util/LoadingPage";
import { DireccionInitAction } from "@redux/actions/direccionDestinoActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiDireccionDestino } from "@api/direccionDestino";

const Direccion = () => {

    const [loaded, setLoaded] = useState(false);

    const distpatch = useDispatch();

    useEffect(() => {
        ApiDireccionDestino.GetAllData().then((resp) => {
            distpatch(DireccionInitAction(resp.data.data));
            setLoaded(true);
        });
        // eslint-disable-next-line
    }, []);

    if (!loaded) return <LoadingPage/>;

    return (
        <div className="container">
            <DireccionCreate />
            <DireccionList />
        </div>
    );
};
export default Direccion;