import { Icon, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import { INavigationSubItem } from "../../../../../ts/interfaces/globalParameters/INavigation";

interface ILayoutPrivateSidebarNavigationSubItem extends INavigationSubItem {
    handleDrawerClose: () => any;
}

const LayoutPrivateSidebarNavigationSubItem = (
    item: ILayoutPrivateSidebarNavigationSubItem
    ) => {    
    return (
        <Link
            to={`/${item.redirect}`}
            onClick={item.handleDrawerClose}
            style={{ textDecoration: "none", display: "block" }}
        >
            <ListItem sx={{ pl: 4 }}>
                <ListItemIcon>
                    <Icon>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText secondary={item.name} />
            </ListItem>
        </Link>
    );
};

export default LayoutPrivateSidebarNavigationSubItem;
