import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

import { ApiAuth } from "@api/auth";
import { GlobalLogin } from "@redux/actions/globalActions";
import { ILogin } from "@interfaces/auth/ILogin";
import { IUsuario } from "@interfaces/models/IUsuario";

const LoginForm = () => {
    const distpatch = useDispatch();
    const form = useForm<IUsuario>();
    const { handleSubmit } = form;

    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
    const [inRequest, setInRequest] = useState<boolean>(false);

    const SubmitData = (data: IUsuario): void => {
        setInRequest(true);
        ApiAuth.PostLogin(data).then((resp: any) => {
            setInRequest(false);
            if (resp.data.success) {
                const loginResponse: ILogin = {
                    ...resp.data
                }
                distpatch(GlobalLogin(loginResponse));

            } else {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "error",
                });
            }
        });
    };



    return (
        <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(SubmitData)}
            sx={{ mt: 1 }}
            style={{ width: "100%" }}
        >
            <STextField
                name="email"
                form={form}
                label="Correo"
                mode="email"
                rules={{
                    required: "Debe ingresar email",
                }}
            />
            <STextField
                type="password"
                label="Password"
                name="password"
                form={form}
                autoComplete="current-password"
                rules={{
                    required: "Debe ingresar la contraseña",
                }}
            />
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
            >
                Ingresar
            </LoadingButton>
            <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
        </Box>
    );
};

export default LoginForm;
