import { useState } from "react";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Container, Grid, InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";

import SSelect from "@controls/inputs/SSelect";
import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

import { ApiCamion } from "@api/camion";
import { currentYearListSelect } from "@helpers/date";
import { vigenteListSelect } from "@helpers/genericList";
import { useAppSelector } from "@redux/store/store";
import { CamionesSaveListAction } from "@redux/actions/camionActions";

const CamionCreate = () => {
  const distpatch = useDispatch();

  const { selectBaseData: { marcas, modelos, codigosMop}} = useAppSelector((storage) => storage.camiones)

  const form = useForm({ mode: "onChange" });

  const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
  const [inRequest, setInRequest] = useState<boolean>(false);

  const { handleSubmit } = form;

  const onSubmit = (data: any) => {
    setInRequest(true);
    ApiCamion.PostCamion(data).then((resp: any) => {
      setInRequest(false);
      if (resp.data.success) {
        form.reset({});

        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(CamionesSaveListAction(resp.data.list));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });
  };

  return (
    <Container component="main">
      <Box>
        <Typography component="h1" variant="h4">
          Formulario de Camiones
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <STextField
                name="patente"
                form={form}
                type="text"
                label="Patente"
                mode="patente"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SSelect
                name="anio"
                form={form}
                label="Año"
                items={currentYearListSelect}
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <STextField
                name="tara"
                form={form}
                label="Tara"
                mode="number"
                rules={{
                  required: "Este campo es requerido",
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SSelect
                label="Marca"
                name="marcaId"
                form={form}
                items={marcas}
                defaultValue={""}
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SSelect
                label="Modelo"
                name="modeloId"
                form={form}
                items={modelos}
                defaultValue={""}
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <SSelect
                label="Vigente"
                name="vigente"
                form={form}
                items={vigenteListSelect}
                defaultValue={""}
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SSelect
                label="Seguro"
                name="seguro"
                form={form}
                items={vigenteListSelect}
                defaultValue={""}
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SSelect
                label="Código Mop"
                name="codigomopId"
                form={form}
                items={codigosMop}
                defaultValue={""}
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
              >
                Guardar camión
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
    </Container>
  );
};

export default CamionCreate;
