import { ISelect, ISelectStringId } from "@interfaces/helpers/ISelect";
import dayjs from "dayjs";

export const DateFormatShort = (date: any) => dayjs(date).format("DD/MM/YYYY");

export const GetYearList = (date: Date = new Date()): number[] => {
    let yearList: number[] = [];

    const maxYear = date.getFullYear();

    for (let index = maxYear; index > 1950; index--) {
        yearList = [...yearList, index];
    }
    return yearList;
};

export const currentYearList = GetYearList();

export const currentYearListSelect: ISelect[] = currentYearList.map((y) => ({
    id: y,
    description: y.toString(),
}));


export const GenerateTemporadaSelect = (): ISelectStringId[] => {
    let items: string[] = []
    let current = new Date().getFullYear() - 1999;
    for (let i = 21; i <= current; i++) {
        items = [...items, `${i} - ${i + 1}`]
    }

    return items.map(i => ({ id: i, description: i }))
}
