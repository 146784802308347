import { Axios } from "../config/axios"
import { ITransporte } from "../interfaces/models/ITransporte";

const endpoint = "transporte/"

const GetAllData = async () => {
    return await Axios().get(endpoint+'data');
}

const GetAllTransporte = async () => {
    return await Axios().get(endpoint+'list');
}

const PostTransporte = async (transporte: ITransporte) => {
    return await Axios().post(endpoint, transporte);
}

const PutTransporte = async (id: number, transporte: ITransporte) => {
    return await Axios().put(`${endpoint}${id}`, transporte);
}



export const ApiTransporte = {
    GetAllData,
    GetAllTransporte,
    PostTransporte,
    PutTransporte
}
