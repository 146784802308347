import { useState, useEffect } from "react";
import { TransportForm } from "./partial/TransportForm";
import { ApiTransporte } from "@api/transporte";
import { TransporteInitSelectAction } from "@redux/actions/transporteActions";
import { useDispatch } from "react-redux";
import LoadingPage from "@controls/util/LoadingPage";
import { ApiCentroCosto } from "@api/centroCostos";

const Home = () => {
  const [loaded, setLoaded] = useState(false);

  const distpatch = useDispatch();

  useEffect(() => {
    ApiTransporte.GetAllData().then((resp: any) => {
      distpatch(TransporteInitSelectAction(resp.data));
      setLoaded(true);
    });

    ApiCentroCosto.GetAll().then((resp) => {
      console.log(resp.data);
    });

    // eslint-disable-next-line
  }, []);

  if (!loaded) return <LoadingPage />;

  return (
    <>
      <TransportForm />
      {/* <TransportList /> */}
    </>
  );
};

export default Home;
