import ModelosCreate from "./partial/ModelosCreate";
import ModelosList from "./partial/ModelosList";

const Modelo = () => {
    
    return (
        <div className="container">
            <ModelosCreate />
            <ModelosList />
        </div>
    );
};
export default Modelo;
