import EmpresaCreate from "./partial/EmpresaDestinoCreate";
import EmpresaList from "./partial/EmpresaDestinoList";

const EmpresaDestino = () => {
    return (
        <div className="container">
            <EmpresaCreate />
            <EmpresaList />
        </div>
    );
};
export default EmpresaDestino;
