import { ICAMIONES_INIT_STATE } from "@interfaces/redux/IStorage";
import { types } from "../types/types";



const INIT_STATE: ICAMIONES_INIT_STATE = {
    camionSelected: null,
    list: [],
    selectBaseData: {
        modelos: [],
        marcas: [],
        codigosMop: []
    },
};

export const CamionesReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.CAMIONES_INIT: {
            return {
                ...state, 
                ...action.payload,
            };
        }
        case types.CAMIONES_SAVE_LIST: {
            return {
                ...state,
                list: action.payload,
                camionSelected: null
            };
        }
        case types.CAMIONES_SELECT_TO_EDIT: {
            return {
                ...state,
                camionSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
