import { IUsuario } from "@interfaces/models/IUsuario";
import { Axios } from "../config/axios"


const endpoint = "usuario/"

const GetAllUsuarios = async () => {
    return await Axios().get(endpoint);
}

const GetUsuario = async (id: number) => {
    return await Axios().get(`${endpoint}${id}`);
}

const PostCreateUsuarios = async (usuario: IUsuario) => {
    return await Axios().post(endpoint, usuario);
}

const PutUpdateUsuarios = async (id: number, usuario: IUsuario) => {
    return await Axios().put(`${endpoint}${id}`, usuario);
}

const DeleteUsuarios = async (id: number) => {
    return await Axios().delete(`${endpoint}${id}`);
}

export const ApiUsuarios = {
    GetAllUsuarios,
    GetUsuario,
    PostCreateUsuarios,
    PutUpdateUsuarios,
    DeleteUsuarios
}