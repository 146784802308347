import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
} from "@mui/material";
import { useEffect, useState } from "react";
import Form from "react-hook-form";

type TSSelectItems =
    | {
        id: any;
        description: any;
    }
    | any;
type TSSelectFilterBy = {
    itemKey: string;
    value: any;
};

type TProps = SelectProps & {
    name: string;
    form: Form.UseFormReturn<any>;
    rules?: Form.RegisterOptions;
    items: TSSelectItems[];
    filterBy?: TSSelectFilterBy;
    textField?: string;
    valueField?: string;
};

const SSelect = (props: TProps) => {
    const {
        register,
        formState: { errors },
        watch,
        setValue
    } = props.form;

    const [items, setItems] = useState(props.items);

    const lbId = "lb" + props.name;

    const { filterBy } = props;

    const value = watch(props.name);

    useEffect(() => {
        if (props.filterBy && !!props?.filterBy?.itemKey) {
            let newItems = props.items.filter(
                (v) =>
                    v[props?.filterBy?.itemKey ?? "id"] ===
                    (props?.filterBy?.value ?? "id")
            );
            setItems(newItems);
            if (!!!newItems.map(e => e[props?.filterBy?.itemKey ?? "id"]).includes(value)){ 
                // setValue(props.name,'');
            }
        } else if (props.filterBy && !!props?.filterBy?.value) {
            setItems([]);
            setValue(props.name,'');
        }
        // eslint-disable-next-line
    }, [filterBy]);

    return (
        <FormControl
            fullWidth
            sx={{
                margin: "5px",
                padding: "0"
            }}
        >
            <InputLabel id={lbId}>{props.label}</InputLabel>
            <Select
                labelId={lbId}
                error={!!errors[props.name]?.message}
                label={props.label}
                {...register(props.name, props.rules)}
                value={value ?? ""}
                fullWidth
                disabled={!items || items.length === 0}
            >
                {!!items && items.map((c) => (
                    <MenuItem
                        key={c[props.valueField ?? "id"]}
                        value={c[props.valueField ?? "id"]}
                    >
                        {c[props.textField ?? "description"]}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText id={props.name + "-error-text"} error>
                {errors[props.name]?.message}
            </FormHelperText>
        </FormControl>
    );
};
export default SSelect;
