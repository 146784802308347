import { Box, Button } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ApiDireccionDestino } from "@api/direccionDestino";
import { IDireccionDestino } from "@interfaces/models/IDireccionDestino";
import {
  DireccionSaveListAction,
  DireccionesSelectToEditAction,
} from "@redux/actions/direccionDestinoActions";

import { useAppSelector } from "@redux/store/store";
import SDataGrid from "@controls/tables/SDataGrid";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

import DireccionEdit from "./DireccionEdit";

const DireccionList = () => {
  const distpatch = useDispatch();

  const { regions: { provincies, communes } } = useAppSelector((state) => state.global);
  const { list, direccionSelected } = useAppSelector((state) => state.direccionDestino);

  const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});

  const Edit = (selected: IDireccionDestino) => {
    let companySelect = { ...selected };

    const communeSelected = communes.find(c => c.id === companySelect.comunaId)
    const provinceSelected = provincies.find(c => c.id === communeSelected?.provinciaId)

    companySelect.regionId = provinceSelected?.regionId ?? 0;
    companySelect.provinciaId = provinceSelected?.id ?? 0;
    distpatch(DireccionesSelectToEditAction(companySelect));
  };

  const handleClose = () => {
    distpatch(DireccionesSelectToEditAction(null));
  };

  const columns: GridColDef[] = [
    {
      field: "direccion",
      headerName: "Dirección",
      flex: 3,
      renderCell: (params: GridValueGetterParams) => (
        <>{params.row.direccion} #{params.row.numeroDireccion}, <br /> {params.row.adicionalDireccion}</>
      ),
    },
    {
      field: "codigointerno",
      headerName: "Código",
      flex: 2
    },
    {
      field: "empresadestinoDesc",
      headerName: "Empresa",
      flex: 2
    },
    {
      field: "comuna",
      headerName: "Comuna",
      flex: 2
    },
    {
      field: "vigente",
      headerName: "Vigente",
      flex: 2,
      renderCell: (params: GridValueGetterParams) => (
        <>{params.row.vigente ? "Sí" : "No"}</>
      ),
    },
    {
      field: "id",
      headerName: "Acción",
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <Button color="warning" onClick={() => Edit(params.row)}>
          Editar
        </Button>
      ),
    },
  ];

  useEffect(() => {
    ApiDireccionDestino.GetDirecciones().then((resp) => {
      distpatch(DireccionSaveListAction(resp.data.data));
    });
    // eslint-disable-next-line
  }, []);

  if (!list) return <></>;

  return (
    <Box sx={{ height: 400, width: "95%", ml: "20px", mr: "20px" }}>
      <SDataGrid rows={list} columns={columns} disableSelectionOnClick />

      <Dialog open={!!direccionSelected} onClose={handleClose}>
        <DialogTitle>Editar Dirección</DialogTitle>
        <DialogContent>
          <DireccionEdit
            handleClose={handleClose}
            setResultAlert={setResultAlert}
          />
        </DialogContent>
      </Dialog>

      <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
    </Box>
  );
};

export default DireccionList;
