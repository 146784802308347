import { ICAMIONES_INIT_STATE } from "@interfaces/redux/IStorage";
import { ICamion } from "../../interfaces/models/ICamion";
import { types } from "../types/types";

export const CamionesInitAction = (data: ICAMIONES_INIT_STATE) => ({
    type: types.CAMIONES_INIT,
    payload: data
})

export const CamionesSaveListAction = (data: ICamion[]) => ({
    type: types.CAMIONES_SAVE_LIST,
    payload: data
})

export const CamionesSelectToEditAction = (data: ICamion | null) => ({
    type: types.CAMIONES_SELECT_TO_EDIT,
    payload: data
})
