import { Container } from "@mui/material";
import LayoutPrivateSidebar from "./partial/LayoutPrivateSidebar";
import LayoutPrivateFooter from "./partial/LayoutPrivateFooter";
import { useEffect, useState } from "react";
import { ApiGlobal } from "@api/global";
import { useAppSelector } from "@redux/store/store";
import { IGlobalParameters } from "@interfaces/globalParameters/IGlobalParameters";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { useDispatch } from "react-redux";
import { GlobalParameters } from "@redux/actions/globalActions";
import LoadingPage from "@controls/util/LoadingPage";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";

type TLayoutPrivate = {
	children: JSX.Element;
};

const LayoutPrivate = ({ children }: TLayoutPrivate) => {
	const distpatch = useDispatch();
	const {
		authentificatedUser: { email },
		rols
	} = useAppSelector((store) => store.global);

	const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
	const [inRequest, setInRequest] = useState<boolean>(true);

	const SaveGlobalData = (): void => {
		setInRequest(true);
		// cargar parametros globales en otra api
		ApiGlobal.getGlobalParameters({ email }).then((resp: any) => {
			if (resp.data.success) {
				const globalResponse: IGlobalParameters = {
					...resp.data
				}
				setInRequest(false);
				distpatch(GlobalParameters(globalResponse));

			}
			else {
				setResultAlert({
					message: resp.data?.message,
					severity: "error",
				});
			}
		})
	};
	useEffect(() => {
		if (rols.length === 0) {
			SaveGlobalData();
		}
		else {
			setInRequest(false);
		}

	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rols]);

	if (inRequest)
		return <LoadingPage />

	return (
		<div
			style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
		>
			<LayoutPrivateSidebar />
			{/* Content */}
			<Container sx={{ mt: 15, flex: 1, padding: "20px" }}>
				{children}
			</Container>
			<LayoutPrivateFooter />
			<SSnackBarAlert state={resultAlert} setState={setResultAlert} />
		</div>
	);
};

export default LayoutPrivate;
