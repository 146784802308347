import { IDireccionDestino } from "@interfaces/models/IDireccionDestino";
import { Axios } from "../config/axios"
import { AxiosResponse } from "axios";
import { IBaseResponse } from "@interfaces/models/IBaseResponse";
import { IAllDataDireccionDestino } from "@interfaces/models/IAllDataDireccionDestino";

const endpoint = "direccionDestino/"

const GetAllData = async () => {
    return await Axios().get<any, AxiosResponse<IBaseResponse<IAllDataDireccionDestino>, any>>(endpoint + "data");
};

const GetDirecciones = async () => {
    return await Axios().get<any, AxiosResponse<IBaseResponse<IDireccionDestino[]>, any>>(endpoint);
}

const GetDireccion = async (id: number) => {
    return await Axios().get<any, AxiosResponse<IBaseResponse<IDireccionDestino>, any>>(`${endpoint}${id}`);
}

const PostCreateDireccion = async (direccionDestino: IDireccionDestino) => {
    return await Axios().post<any, AxiosResponse<IBaseResponse<IDireccionDestino[]>, any>>(endpoint, direccionDestino);
}

const PutUpdateDireccion = async (id: number, direccionDestino: IDireccionDestino) => {
    return await Axios().put<any, AxiosResponse<IBaseResponse<IDireccionDestino[]>, any>>(`${endpoint}${id}`, direccionDestino);
}

export const ApiDireccionDestino = {
    GetAllData,
    GetDirecciones,
    GetDireccion,
    PostCreateDireccion,
    PutUpdateDireccion,
}