
import { ITIPOCARGA_INIT_STATE } from '@interfaces/redux/IStorage';
import { types } from "../types/types";


const INIT_STATE: ITIPOCARGA_INIT_STATE = {
    tipoCargaSelected: null,
    list: []
};

export const TiposCargaReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.TIPOCARGA_SAVE_LIST: {
            return {
                ...state,
                list: action.payload,
                tipoCargaSelected: null
            };
        }
        case types.TIPOCARGA_SELECT_TO_EDIT: {
            return {
                ...state,
                tipoCargaSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
