import { IMarca } from "@interfaces/models/IMarca";
import { types } from "../types/types";

export const MarcaSaveListAction = (data: IMarca[]) => ({
    type: types.MARCA_SAVE_LIST,
    payload: data
})

export const MarcaSelectToEditAction = (data: IMarca | null) => ({
    type: types.MARCA_SELECT_TO_EDIT,
    payload: data
})
