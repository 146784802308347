import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@redux/store/store";
import STextField from "@controls/inputs/STextField";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

import { ApiUsuarios } from "@api/usuarios";
import { UsuarioSave } from "@redux/actions/usuariosActions";
import SSelect from "@controls/inputs/SSelect";
import { IUsuario } from "@interfaces/models/IUsuario";


interface IUsuarioEdit {
    handleClose: () => any;
    setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const UsuariosEdit = ({ handleClose, setResultAlert }: IUsuarioEdit) => {
    const distpatch = useDispatch();

    const { usuarioSelected } = useAppSelector((store) => store.usuarios);
    const { rols } = useAppSelector((state) => state.global);

    const form = useForm({ defaultValues: usuarioSelected ?? {} });

    const { handleSubmit } = form;

    const [inRequest, setInRequest] = useState<boolean>(false);

    const onSubmit = (data: IUsuario) => {
        setInRequest(true);
        ApiUsuarios.PutUpdateUsuarios(data.id, data).then((resp: any) => {
            setInRequest(false);
            if (resp.data.success) {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "success",
                });

                distpatch(UsuarioSave(resp.data.list));
            } else {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "error",
                });
            }
        });
    };
    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <STextField
                        name="nombre"
                        form={form}
                        type="text"
                        label="Nombre y Apellido"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="email"
                        form={form}
                        type="text"
                        label="Correo"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SSelect
                            label="Rol"
                            name="idRol"
                            form={form}
                            items={rols}
                            textField="descripcion"
                            defaultValue={""}
                            rules={{
                                required: "Este campo es requerido",
                            }}
                        />
                </Grid>
            </Grid>
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
            >
                Actualizar
            </LoadingButton>
            <Button fullWidth onClick={handleClose} variant="outlined">
                Cancel
            </Button>
        </Box>
    );
};
export default UsuariosEdit;
