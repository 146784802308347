import { ICentroCostos } from "@interfaces/models/ICentroCostos";
import { types } from "../types/types";

export const CentroCostosSaveListAction = (data: ICentroCostos[]) => ({
    type: types.CENTROCOSTOS_SAVE_LIST,
    payload: data
})

export const CentroCostosSelectToEditAction = (data: ICentroCostos | null) => ({
    type: types.CENTROCOSTOS_SELECT_TO_EDIT,
    payload: data
})
