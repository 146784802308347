import ConductoresCreate from "./partial/ConductoresCreate";
import ConductoresList from "./partial/ConductoresList";

const Conductores = () => {
    
    return (
        <div className="container">
            <ConductoresCreate />
            <ConductoresList />
        </div>
    );
};
export default Conductores;
