import { Axios } from "@config/axios"
import { IModelo } from "@interfaces/models/IModelo";

const endpoint = "modelo/"

const GetAllModelo = async () => {
    return await Axios().get(endpoint);
}

const GetModelo = async (id: number) => {
    return await Axios().get(`${endpoint}${id}`);
}

const PostCreateModelo = async (modelo: IModelo) => {
    return await Axios().post(endpoint, modelo);
}

const PutUpdateModelo = async (id: number, modelo: IModelo) => {
    return await Axios().put(`${endpoint}${id}`, modelo);
}

const DeleteModelo = async (id: number) => {
    return await Axios().delete(`${endpoint}${id}`);
}

export const ApiModelo = {
    GetAllModelo,
    GetModelo,
    PostCreateModelo,
    PutUpdateModelo,
    DeleteModelo
}