import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import { useAppSelector } from "@redux/store/store";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ConductoresSaveListAction } from "@redux/actions/conductoresActions";
import { IConductor } from "@interfaces/models/IConductor";
import { ApiConductor } from "@api/conductor";
import STextField from "@controls/inputs/STextField";
import { vigenteListSelect } from "@helpers/genericList";
import SSelect from "@controls/inputs/SSelect";

interface IConductoresEdit {
    handleClose: () => any;
    setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const ConductoresEdit = ({ handleClose, setResultAlert }: IConductoresEdit) => {
    const distpatch = useDispatch();
    const { conductorSelected } = useAppSelector((store) => store.conductores);

    const form = useForm({ defaultValues: conductorSelected ?? {} });

    const { handleSubmit } = form;

    const [inRequest, setInRequest] = useState<boolean>(false);

    const onSubmit = (data: IConductor) => {
        setInRequest(true);
        ApiConductor.PutConductor(data.id, data).then((resp: any) => {
            setInRequest(false);
            if (resp.data.success) {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "success",
                });

                distpatch(ConductoresSaveListAction(resp.data.list));
            } else {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "error",
                });
            }
        });
    };
    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <STextField
                        name="rut"
                        form={form}
                        type="text"
                        label="Rut"
                        mode="rut"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="nombre"
                        form={form}
                        type="text"
                        label="Nombre y Apellido"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="correo"
                        form={form}
                        type="text"
                        label="Correo"
                        mode="email"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="telefono"
                        form={form}
                        type="text"
                        label="Teléfono"
                        mode="phone"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SSelect
                        label="Contratado"
                        name="contratado"
                        form={form}
                        items={vigenteListSelect}
                        defaultValue={""}
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
            </Grid>
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
            >
                Actualizar
            </LoadingButton>
            <Button fullWidth onClick={handleClose} variant="outlined">
                Cancel
            </Button>
        </Box>
    );
};
export default ConductoresEdit;
