import { Box, Button } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiMarca } from "@api/marca";
import { IMarca } from "@interfaces/models/IMarca";
import {
  MarcaSaveListAction,
  MarcaSelectToEditAction,
} from "@redux/actions/marcasActions";
import { useAppSelector } from "@redux/store/store";
import SDataGrid from "@controls/tables/SDataGrid";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import MarcasEdit from "./MarcasEdit";

const MarcaList = () => {
  const distpatch = useDispatch();

  const { list, marcaSelected } = useAppSelector((state) => state.marcas);

  const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});

  const Edit = (selected: IMarca) => {
    distpatch(MarcaSelectToEditAction(selected));
  };

  const handleClose = () => {
    distpatch(MarcaSelectToEditAction(null));
  };

  const columns: GridColDef[] = [
    {
      field: "descripcion",
      headerName: "Nombre",
      flex: 3,
    },
    {
      field: "id",
      headerName: "Acción",
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <Button color="warning" onClick={() => Edit(params.row)}>
          Editar
        </Button>
      ),
    },
  ];

  useEffect(() => {
    ApiMarca.GetAllMarca().then((resp: any) => {
      distpatch(MarcaSaveListAction(resp.data));
    });
    // eslint-disable-next-line
  }, []);

  if (!list) return <></>;

  return (
    <Box sx={{ height: 400, width: "95%", ml: "20px", mr: "20px" }}>
      <SDataGrid rows={list} columns={columns} disableSelectionOnClick />

      <Dialog open={!!marcaSelected} onClose={handleClose}>
        <DialogTitle>Editar Marca</DialogTitle>
        <DialogContent>
          <MarcasEdit
            handleClose={handleClose}
            setResultAlert={setResultAlert}
          />
        </DialogContent>
      </Dialog>

      <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
    </Box>
  );
};

export default MarcaList;
