import { LoadingButton } from "@mui/lab";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApiVariedadCarga } from "@api/variedadcarga";
import { VariedadCargaSaveListAction } from "@redux/actions/variedadesCargaActions";
import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import SSelect from "@controls/inputs/SSelect";
import { useAppSelector } from "@redux/store/store";

const VariedadCargaCreate = () => {
  const distpatch = useDispatch();

  const {selectBaseData: { tiposCarga }} = useAppSelector((storage) => storage.variedadesCarga);

  const form = useForm({ mode: "onChange" });

  const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
  const [inRequest, setInRequest] = useState<boolean>(false);

  const { handleSubmit } = form;

  const onSubmit = (data: any) => {
    setInRequest(true);
    ApiVariedadCarga.PostVariedadCarga(data).then((resp: any) => {
      setInRequest(false);
      if (resp.data.success) {
        form.reset({});

        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(VariedadCargaSaveListAction(resp.data.list));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });

    console.log(data)
  };

  return (
    <Container component="main">
      <Box>
        <Typography component="h1" variant="h4">
          Formulario Variedad de Carga
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <STextField
                name="descripcion"
                form={form}
                type="text"
                label="Nombre Variedad de Carga"
                mode="descripciones"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>           
            <Grid item xs={12} sm={4}>
              <SSelect
                label="Tipo de carga"
                name="tipocargaId"
                form={form}
                items={tiposCarga}
                defaultValue={""}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
              >
                Guardar Variedad de Carga
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
    </Container>
  );
};

export default VariedadCargaCreate;
