import { LoadingButton } from "@mui/lab";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState} from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

import { ApiUsuarios } from "@api/usuarios";
import { UsuarioSave } from "@redux/actions/usuariosActions";
import SSelect from "@controls/inputs/SSelect";
import { useAppSelector } from "@redux/store/store";


const UsuariosCreate = () => {

    const distpatch = useDispatch();

    const { rols } = useAppSelector((state) => state.global);
    const form = useForm({ mode: "onChange" });

    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
    const [inRequest, setInRequest] = useState<boolean>(false);

    const { handleSubmit } = form;

    const onSubmit = (data: any) => {
        setInRequest(true);
        ApiUsuarios.PostCreateUsuarios(data).then((resp: any) => {
            setInRequest(false);
            if (resp.data.success) {

                form.reset({});

                setResultAlert({
                    message: resp.data?.message,
                    severity: "success",
                });

                distpatch(UsuarioSave(resp.data.list));
            } else {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "error",
                });
            }
        });
    };

    return (
        <Container component="main">
            <Box>
                <Typography component="h1" variant="h4">
                    Formulario de Usuarios
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="nombre"
                                form={form}
                                type="text"
                                label="Nombre y Apellido"
                                mode='nombreyapellido'
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="email"
                                form={form}
                                type="text"
                                label="Correo"
                                mode="email"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <SSelect
                                label="Rol"
                                name="idRol"
                                form={form}
                                items={rols}
                                textField="descripcion"
                                defaultValue={""}
                                rules={{
                                    required: "Este campo es requerido",
                                }}
							/>
                        </Grid>

                        <Grid item xs={12} display="flex" justifyContent="flex-end">
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                loading={inRequest}
                            >
                                Guardar Usuario
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
        </Container>
    );
};



export default UsuariosCreate;
