import { IConductor } from "@interfaces/models/IConductor";
import { types } from "../types/types";

export const ConductoresSaveListAction = (data: IConductor[]) => ({
    type: types.CONDUCTORES_SAVE_LIST,
    payload: data
})

export const ConductoresSelectToEditAction = (data: IConductor | null) => ({
    type: types.CONDUCTORES_SELECT_TO_EDIT,
    payload: data
})
