import { TypedUseSelectorHook, useSelector } from "react-redux";
import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadFromSessionStorage, saveToSessionStorage } from "./localStorage";
import { IStorage } from "@interfaces/redux/IStorage";

import { AcopladosReducers } from "../reducers/acopladosReducers";
import { GlobalReducers } from "../reducers/globalReducers";
import { UsuariosReducers } from "../reducers/usuariosReducers";
import { EmpresaTransportistaReducers } from '../reducers/empresacamionReducers';
import { ConductoresReducers } from "../reducers/conductoresReducers";
import { EmpresaDestinoReducers } from "../reducers/empresaDestinoReducers";
import { MarcasReducers } from "../reducers/marcasReducers";
import { ModelosReducers } from "../reducers/modelosReducers";
import { CamionesReducers } from "../reducers/camionesReducers";
import { transporteReducers } from "../reducers/transporteReducers";
import { TiposCargaReducers } from "@redux/reducers/tiposcargaReducers";
import { VariedadesCargaReducers } from "@redux/reducers/variedadescargaReducers";
import { direccionDestinoReducers } from "@redux/reducers/direccionDestinoReducers";
import { CentroCostosReducers } from "@redux/reducers/centrocosotosReducers";



const reducers = combineReducers({
  global: GlobalReducers,
  acoplados: AcopladosReducers,
  empresaTransportista: EmpresaTransportistaReducers,
  empresaDestino: EmpresaDestinoReducers,
  conductores: ConductoresReducers,
  usuarios: UsuariosReducers,
  marcas: MarcasReducers,
  modelos: ModelosReducers,
  camiones: CamionesReducers,
  transporte: transporteReducers,
  tiposCarga: TiposCargaReducers,
  variedadesCarga: VariedadesCargaReducers,
  direccionDestino: direccionDestinoReducers,
  centroCostos: CentroCostosReducers
});
/* eslint-disable no-underscore-dangle */
export const store = createStore(
  reducers,
  loadFromSessionStorage(),
  composeWithDevTools()
);
/* eslint-enable */

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<IStorage> = useSelector

// listen for store changes and use saveToLocalStorage to
// save them to localStorage en cada cambio
store.subscribe(() => saveToSessionStorage(store.getState()));
//save on close o refresh
// window.onbeforeunload = () => saveToSessionStorage(store.getState())