import { Box, Button } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";

import EmpresaEdit from "./EmpresaDestinoEdit";
import { useAppSelector } from "@redux/store/store";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { IEmpresaDestino } from "@interfaces/models/IEmpresaDestino";
import { EmpresaDestinoSaveListAction, EmpresaDestinoSelectToEditAction } from "@redux/actions/empresaDestinoActions";
import { ApiEmpresaDestino } from "@api/empresaDestino";
import SDataGrid from "@controls/tables/SDataGrid";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";

const EmpresaDestinoList = () => {
    const distpatch = useDispatch();

    const { list, empresaDestinoSelected } = useAppSelector((state) => state.empresaDestino);
    const { regions: { provincies, communes }  } = useAppSelector((state) => state.global);

    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});

    const Edit = (selected: IEmpresaDestino) => {

        let companySelect = {...selected};

        const communeSelected = communes.find(c => c.id === companySelect.comunaId)
        const provinceSelected = provincies.find(c => c.id === communeSelected?.provinciaId)

        companySelect.regionId = provinceSelected?.regionId ?? 0;
        companySelect.provinciaId = provinceSelected?.id ?? 0;
        console.log(companySelect);
        distpatch(EmpresaDestinoSelectToEditAction(companySelect));
    }

    const handleClose = () => {
        distpatch(EmpresaDestinoSelectToEditAction(null));
    };
    const columns: GridColDef[] = [
        {
            field: "rut",
            headerName: "Rut",
            flex: 2
        },
        {
            field: "descripcion",
            headerName: "Descripción",
            flex: 2
        },
        {
            field: "codigoInterno",
            headerName: "Cod. Interno",
            flex: 2
        },
        {
            field: "correo",
            headerName: "Correo",
            flex: 2
        },
        {
            field: "direccion",
            headerName: "Dirección",
            flex: 3,
            renderCell: (params: GridValueGetterParams) => (
                <>{params.row.direccion} #{params.row.numeroDireccion}, <br/> {params.row.adicionalDireccion}</>
            ),
        },
        {
            field: "vigente",
            headerName: "Vigente",
            flex: 2,
            renderCell: (params: GridValueGetterParams) => (
                <>{params.row.vigente ? "Sí" : "No"}</>
            ),
        },
        {
            field: "comunaDesc",
            headerName: "Comuna",
            flex: 2
        },
        {
            field: "id",
            headerName: "Acción",
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: GridValueGetterParams) => (
                <Button color="warning" onClick={() => Edit(params.row)}>Editar</Button>
            ),
        },
    ];

    useEffect(() => {
        ApiEmpresaDestino.GetEmpresasDestino().then((resp: any) => {
            distpatch(EmpresaDestinoSaveListAction(resp.data));
        });
        // eslint-disable-next-line
    }, []);

    if (!list) return <></>;

    return (
        <Box sx={{ height: 400, width: "95%", ml: "20px", mr: "20px" }}>
            <SDataGrid rows={list} columns={columns} disableSelectionOnClick />

            <Dialog open={!!empresaDestinoSelected} onClose={handleClose} >
                <DialogTitle>Editar Empresa de Destino</DialogTitle>
                <DialogContent>
                    <EmpresaEdit
                        handleClose={handleClose} setResultAlert={setResultAlert} />
                </DialogContent>
            </Dialog>

            <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
        </Box>
    );
};
export default EmpresaDestinoList;
