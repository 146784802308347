import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, InputAdornment } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApiAcoplado } from "@api/acoplado";
import { IAcoplado } from "@interfaces/models/IAcoplado";
import { AcopladosSaveListAction } from "@redux/actions/acopladosActions";
import { useAppSelector } from "@redux/store/store";
import STextField from "@controls/inputs/STextField";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

interface IAcopladosEdit {
  handleClose: () => any;
  setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const AcopladosEdit = ({ handleClose, setResultAlert }: IAcopladosEdit) => {
  const distpatch = useDispatch();
  const { acopladoSelected } = useAppSelector((store) => store.acoplados);

  const form = useForm({ defaultValues: acopladoSelected ?? {} });

  const { handleSubmit } = form;

  const [inRequest, setInRequest] = useState<boolean>(false);

  const onSubmit = (data: IAcoplado) => {
    setInRequest(true);
    ApiAcoplado.PutAcoplado(data.id, data).then((resp: any) => {
      setInRequest(false);
      if (resp.data.success) {
        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(AcopladosSaveListAction(resp.data.list));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <STextField
            name="descripcion"
            form={form}
            type="text"
            label="Nombre acoplado"
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            name="patente"
            form={form}
            type="text"
            label="Patente"
            mode="patente"
            rules={{
              required: "Este campo es requerido",
              minLength: {
                value: 6,
                message: "Patente debe tener al menos 6 caracteres",
              },
              maxLength: { value: 6, message: "Supera el máximo permitido" },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            name="tara"
            form={form}
            mode="number"
            label="Tara"
            rules={{
              required: "Este campo es requerido",
              maxLength: { value: 4, message: "Supera el máximo permitido" },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={inRequest}
      >
        Actualizar
      </LoadingButton>
      <Button fullWidth onClick={handleClose} variant="outlined">
        Cancel
      </Button>
    </Box>
  );
};
export default AcopladosEdit;
