import { CircularProgress } from "@mui/material";
import './LoadingPage.css';

type TLoadingPage = {
    loading?: boolean;
};

const LoadingPage = ({ loading = true }: TLoadingPage) => {
    if (!loading) return <></>;
    return (
        <div className="loading-page-container">
            <CircularProgress />
        </div>
    );
};

export default LoadingPage;
