import { Box, Button } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@redux/store/store";
import SDataGrid from "@controls/tables/SDataGrid";
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

import UsuariosEdit from "./UsuariosEdit";
import { ApiUsuarios } from "@api/usuarios";
import { UsuarioSave, UsuariosSelectToEditAction } from "@redux/actions/usuariosActions";
import { DateFormatShort } from "@helpers/date";
import { IUsuario } from "@interfaces/models/IUsuario";

const UsuariosList = () => {
    const distpatch = useDispatch();

    const { list, usuarioSelected } = useAppSelector((state) => state.usuarios);
    
    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});

    const Edit = (selected: IUsuario) => {
        distpatch(UsuariosSelectToEditAction(selected));
    }

    const handleClose = () => {
        distpatch(UsuariosSelectToEditAction(null));
    };


    const columns: GridColDef[] = [
        {
            field: "nombre",
            headerName: "Nombre",
            flex: 4
        },
        {
            field: "email",
            headerName: "Correo",
            flex: 4
        },
        {
            field: "fechaCreacion",
            headerName: "fechaCreacion",
            flex: 2,
            renderCell: (params: GridValueGetterParams) => (
                DateFormatShort(params.value)
            ),
        },
        {
            field: "descRol",
            headerName: "Rol",
            flex: 2
        },
        {
            field: "id",
            headerName: "Acción",
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: GridValueGetterParams) => (
                <Button color="warning" onClick={() => Edit(params.row)}>Editar</Button>
            ),
        },
    ];

    useEffect(() => {
        ApiUsuarios.GetAllUsuarios().then((resp: any) => {
            distpatch(UsuarioSave(resp.data));
        });
        // eslint-disable-next-line
    }, []);

    if (!list) return <></>;

    return (
        <Box sx={{ height: 400, width: "95%", ml: "20px", mr: "20px" }}>
            <SDataGrid rows={list} columns={columns} disableSelectionOnClick />

            <Dialog open={!!usuarioSelected} onClose={handleClose} >
                <DialogTitle>Editar Usuario</DialogTitle>
                <DialogContent>
                    <UsuariosEdit
                        handleClose={handleClose} setResultAlert={setResultAlert} />
                </DialogContent>
            </Dialog>

            <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
        </Box>
    );
};
export default UsuariosList;
