import TiposCargaCreate from "./partial/TiposCargaCreate";
import TiposCargaList from "./partial/TiposCargaList";

const TiposCarga = () => {
    
    return (
        <div className="container">
            <TiposCargaCreate />
            <TiposCargaList />
        </div>
    );
};
export default TiposCarga;
