import UsuariosCreate from "./partial/UsuariosCreate";
import UsuariosList from "./partial/UsuariosList";


const Usuarios = () => {
    return (
        <div className="container">
            <UsuariosCreate />
            <UsuariosList />
        </div>
    );
};

export default Usuarios;