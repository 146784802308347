import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, InputAdornment } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApiCamion } from "@api/camion";
import { ICamion } from "@interfaces/models/ICamion";
import { CamionesSaveListAction } from "@redux/actions/camionActions";
import { useAppSelector } from "@redux/store/store";
import STextField from "@controls/inputs/STextField";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import SSelect from "@controls/inputs/SSelect";
import { currentYearListSelect } from "@helpers/date";
import { vigenteListSelect } from "@helpers/genericList";

interface ICamionesEdit {
  handleClose: () => any;
  setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const CamionEdit = ({ handleClose, setResultAlert }: ICamionesEdit) => {
  const distpatch = useDispatch();

  const { camionSelected } = useAppSelector((store) => store.camiones);

  const {
    selectBaseData: { marcas, modelos, codigosMop },
  } = useAppSelector((storage) => storage.camiones);

  const form = useForm({ defaultValues: camionSelected ?? {} });

  const { handleSubmit } = form;

  const [inRequest, setInRequest] = useState<boolean>(false);

  const onSubmit = (data: ICamion) => {
    setInRequest(true);
    ApiCamion.PutCamion(data.id, data).then((resp: any) => {
      setInRequest(false);
      if (resp.data.success) {
        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(CamionesSaveListAction(resp.data.list));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <STextField
            label="Patente"
            name="patente"
            form={form}
            type="text"
            rules={{
              required: "Este campo es requerido",
              minLength: {
                value: 6,
                message: "Patente debe tener al menos 6 caracteres",
              },
              maxLength: { value: 6, message: "Supera el máximo permitido" },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SSelect
            label="Año"
            name="anio"
            form={form}
            items={currentYearListSelect}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            label="Tara"
            name="tara"
            form={form}
            mode="number"
            rules={{
              required: "Este campo es requerido",
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">kg</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SSelect
            label="Marca"
            name="camionMarcaId"
            type="number"
            form={form}
            items={marcas}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SSelect
            label="Modelo"
            name="camionModeloId"
            type="number"
            form={form}
            items={modelos}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SSelect
            label="Vigente"
            name="vigente"
            form={form}
            type="text"
            items={vigenteListSelect}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <SSelect
            label="Seguro"
            name="seguro"
            form={form}
            items={vigenteListSelect}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SSelect
            label="Código Mop"
            name="camionCodigoMopId"
            form={form}
            items={codigosMop}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={inRequest}
      >
        Actualizar
      </LoadingButton>
      <Button fullWidth onClick={handleClose} variant="outlined">
        Cancel
      </Button>
    </Box>
  );
};

export default CamionEdit;
