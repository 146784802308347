import { Box, Button } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { IAcoplado } from "@interfaces/models/IAcoplado";
import { AcopladosSaveListAction, AcopladosSelectToEditAction } from "@redux/actions/acopladosActions";
import { useAppSelector } from "@redux/store/store";
import SDataGrid from "@controls/tables/SDataGrid";
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import AcopladosEdit from "./AcopladosEdit";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { ApiAcoplado } from "@api/acoplado";

const AcopladosList = () => {
    const distpatch = useDispatch();

    const { list, acopladoSelected } = useAppSelector((state) => state.acoplados);

    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});

    const Edit = (selected: IAcoplado) => {
        distpatch(AcopladosSelectToEditAction(selected));
    }

    const handleClose = () => {
        distpatch(AcopladosSelectToEditAction(null));
    };

    const columns: GridColDef[] = [
        {
            field: "descripcion",
            headerName: "Nombre",
            flex: 3
        },
        {
            field: "patente",
            headerName: "Patente",
            flex: 3
        },
        {
            field: "tara",
            headerName: "Tara",
            flex: 3
        },
        {
            field: "id",
            headerName: "Acción",
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: GridValueGetterParams) => (
                <Button color="warning" onClick={() => Edit(params.row)}>Editar</Button>
            ),
        },
    ];

    useEffect(() => {
        ApiAcoplado.GetAcoplados().then((resp: any) => {
            distpatch(AcopladosSaveListAction(resp.data));
        });
        // eslint-disable-next-line
    }, []);

    if (!list) return <></>;

    return (
        <Box sx={{ height: 400, width: "95%", ml: "20px", mr: "20px" }}>
            <SDataGrid rows={list} columns={columns} disableSelectionOnClick />

            <Dialog open={!!acopladoSelected} onClose={handleClose} >
                <DialogTitle>Editar Acoplado</DialogTitle>
                <DialogContent>
                    <AcopladosEdit
                        handleClose={handleClose} setResultAlert={setResultAlert} />
                </DialogContent>
            </Dialog>

            <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
        </Box>
    );
};
export default AcopladosList;
