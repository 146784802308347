import { ISelect, ISelect2, ISelectStringId } from "@interfaces/helpers/ISelect";
import { GenerateTemporadaSelect } from "./date";

export const vigenteListSelect: ISelect[] = [
    { id: 1, description: "Sí" },
    { id: 0, description: "No" },
];

export const confirmListSelect: ISelect[] = [
    { id: 1, description: "Sí" },
    { id: 0, description: "No" },
];

export const PesoGuiaListSelect: ISelect[] = [
    { id: 10000, description: "10.000" },
    { id: 30000, description: "30.000" },
    { id: -1, description: "Otro" },
];

export const TipoDocumento: ISelect2[] = [
    { id: "103", description: "(103) Liquidación" },
    { id: "801", description: "(801) Orden de compra" },
    { id: "803", description: "(803) Contrato" },
];


export const CondPago: ISelect[] = [
    { id: -1, description: "Contado" },
    { id: 1, description: "30 días" },
    { id: 2, description: "60 Días" },
    { id: 3, description: "90 Días" },
    { id: 4, description: "120 Dias" },
    { id: 5, description: "150 Dias" },
    { id: 6, description: "180 Dias" },
    { id: 7, description: "7 Dias" },
    { id: 8, description: "10 Dias" },
];

export const TipoDespachoSII: ISelect[] = [
    { id: 0, description: "Sin Despacho" },
    { id: 1, description: "Por cuenta del receptor" },
    { id: 2, description: "Por cuenta del Emisor" },
    { id: 3, description: "A otras Instalaciones" },
];

export const IndTrasladoSII: ISelect[] = [
    { id: 0, description: "Sin Traslado" },
    { id: 1, description: "Constituye Venta" },
    { id: 2, description: "Venta por Efectuar" },
    { id: 3, description: "Consignacion" },
    { id: 4, description: "Entrega Gratuita" },
    { id: 5, description: "Traslado Interno" },
    { id: 6, description: "Otros Traslados" },
    { id: 7, description: "Guia Devolucion" },
    { id: 8, description: "Traslado por Exportacion" },
    { id: 9, description: "Venta por Exportacion" },
];

export const formaPago: ISelect[] = [
    { id: 1, description: "Contado" },
    { id: 2, description: "Crédito" },
];

export const temporada = GenerateTemporadaSelect();

export const bodegaOrigen: ISelectStringId[] = [
    { id: "1", description: "BODEGA ASISA" },
    { id: "2", description: "BODEGA ARIZONA" },
    { id: "3", description: "BODEGA PT" },
];

export const tipoOtraMoneda: ISelectStringId[] = [
    { id: "DOLAR USA", description: "DOLAR USA" },
];

//test