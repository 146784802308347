import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import SSelect from "@controls/inputs/SSelect";

import { ApiDireccionDestino } from "@api/direccionDestino";
import { IDireccionDestino } from "@interfaces/models/IDireccionDestino";
import { DireccionSaveListAction } from "@redux/actions/direccionDestinoActions";


import { useAppSelector } from "@redux/store/store";
import STextField from "@controls/inputs/STextField";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

interface IDireccionEdit {
  handleClose: () => any;
  setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const DireccionEdit = ({ handleClose, setResultAlert }: IDireccionEdit) => {
  const distpatch = useDispatch();

  const { selectEmpresasDestino: selectBaseData, direccionSelected } = useAppSelector((state) => state.direccionDestino);
  const { regions: { regions, provincies, communes } } = useAppSelector((state) => state.global);

  const form = useForm({ defaultValues: direccionSelected ?? {} });

  const { handleSubmit, watch } = form;

  const [inRequest, setInRequest] = useState<boolean>(false);

  const { regionId, provinciaId } = watch();

  const onSubmit = (data: IDireccionDestino) => {
    setInRequest(true);
    ApiDireccionDestino.PutUpdateDireccion(data.id, data).then((resp) => {
      setInRequest(false);
      if (resp.data.success) {
        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(DireccionSaveListAction(resp.data.data));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SSelect
            label="Empresa"
            name="empresadestinoId"
            form={form}
            items={selectBaseData}
            defaultValue={""}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            name="codigointerno"
            form={form}
            type="text"
            label="Código Interno"
            mode="nombreempresa"
            rules={{
              maxLength: {
                message: "No debe ser más de 30 caracteres",
                value: 30,
              },
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            name="descripcion"
            form={form}
            type="text"
            label="Dirección empresa"
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            name="numeroDireccion"
            form={form}
            type="text"
            label="Número de Calle"
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            name="adicionalDireccion"
            form={form}
            type="text"
            label="Dirección complementaria (opcional)"
          />
        </Grid>
        <Grid item xs={12}>
          <SSelect
            label="Región"
            name="regionId"
            form={form}
            items={regions}
            textField="descripcion"
            defaultValue={""}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SSelect
            label="Provincia"
            name="provinciaId"
            form={form}
            items={provincies}
            textField="descripcion"
            defaultValue={""}
            filterBy={{
              value: regionId,
              itemKey: "regionId",
            }}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SSelect
            label="Comuna"
            name="comunaId"
            form={form}
            items={communes}
            textField="descripcion"
            filterBy={{
              value: provinciaId,
              itemKey: "provinciaId",
            }}
            defaultValue={""}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={inRequest}
      >
        Actualizar
      </LoadingButton>
      <Button fullWidth onClick={handleClose} variant="outlined">
        Cancel
      </Button>
    </Box>
  );
};
export default DireccionEdit;
