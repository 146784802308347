import {
    Autocomplete,
    FormControl,
    FormHelperText,
    TextField,
} from "@mui/material";
import Form from "react-hook-form";

type TSSelectItems =
    | {
        id: any;
        description: any;
    }
    | any;
interface IProps {
    // extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
    name: string;
    form: Form.UseFormReturn<any>;
    items: TSSelectItems[];
    label: any;
    rules?: Form.RegisterOptions;
    textField?: string;
    valueField?: string;
}

const SAutoComplete = (props: IProps) => {
    const {
        register,
        formState: { errors },
        setValue,
    } = props.form;

    return (
        <FormControl
            fullWidth
            sx={{
                margin: "5px",
                padding: "0",
            }}
        >
            <Autocomplete
                disablePortal
                options={
                    props.items?.map((i) => ({
                        id: i[props.valueField ?? "id"],
                        label: i[props.textField ?? "description"],
                    })) ?? []
                }
                {...register(props.name, { ...props.rules })}
                onChange={(e, value) => setValue(props.name, value?.id, {shouldTouch: true, shouldDirty: true})}
                renderInput={(params) => (
                    <>
                        <TextField
                            {...params}
                            label={props.label}
                            error={!!errors[props.name]?.message}
                        />
                    </>
                )}
            />

            <FormHelperText id={props.name + "-error-text"} error>
                {errors[props.name]?.message}
            </FormHelperText>
        </FormControl>
    );
};

export default SAutoComplete;
