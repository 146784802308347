import { Switch, Route } from "react-router-dom";
import LayoutPrivate from "../components/layouts/private/LayoutPrivate";
import Home from "../components/pages/private/home/Home";
import Acoplados from "../components/pages/private/acoplados/Acoplados";
import Conductores from "../components/pages/private/conductores/Conductores";
import EmpresaTransportista from "../components/pages/private/empresaTransportista/EmpresaTransportista";
import Usuarios from "../components/pages/private/usuarios/Usuarios";
import Marcas from "../components/pages/private/marcas/Marcas";
import Modelos from "../components/pages/private/modelo/Modelos";
import Empresa from "../components/pages/private/empresaDestino/EmpresaDestino";
import DireccionDestino from "../components/pages/private/direccion/Direccion";
import Camiones from '../components/pages/private/camiones/Camiones';
import TiposCarga from "../components/pages/private/tiposCarga/TiposCarga";
import VariedadesCarga from "../components/pages/private/variedadesCarga/VariedadesCarga";
import Pesaje from "../components/pages/private/pesaje/Pesaje";
import CentroCostos from "../components/pages/private/centroCostos/CentroCostos";

const RoutesPrivate = () => {
	return (
		<LayoutPrivate>
			<Switch>
				<Route strict path="/home">
					<Home />{" "}
				</Route>
				<Route strict path="/Usuarios">
					<Usuarios />{" "}
				</Route>
				<Route strict path="/Acoplados">
					<Acoplados />{" "}
				</Route>
				<Route strict path="/Conductores">
					<Conductores />{" "}
				</Route>
				<Route strict path="/Camiones">
					<Camiones />{" "}
				</Route>
				<Route strict path="/EmpresasTransportista">
					<EmpresaTransportista />{" "}
				</Route>
				<Route strict path="/Marcas">
					<Marcas />{" "}
				</Route>
				<Route strict path="/Modelos">
					<Modelos />{" "}
				</Route>
				<Route strict path="/EmpresasDestino">
					<Empresa />{" "}
				</Route>
				<Route strict path="/DireccionDestino">
					<DireccionDestino />{" "}
				</Route>
				<Route strict path="/TiposCarga">
					<TiposCarga />{" "}
				</Route>
				<Route strict path="/VariedadesCarga">
					<VariedadesCarga />{" "}
				</Route>
				<Route strict path="/pesaje">
					<Pesaje />{" "}
				</Route>
				<Route strict path="/Centrocostos">
					<CentroCostos />{" "}
				</Route>
				<Route path="*">
					<Home />{" "}
				</Route>
			</Switch>
		</LayoutPrivate>
	);
};

export default RoutesPrivate;
