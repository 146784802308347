export const ConvertToDecimal = (value: string, fixed: number = 2) => {
    if (value === undefined || value === "" || !!!value) return "";

    let resultado = value.toString().replace(/[^0-9.]/g, function (m, g1) {
        return g1 || "";
    });
    let resultArray = resultado.split(".");

    if (resultArray.length > 2) resultado = resultado.replace(/\.+$/, "");
    if (resultArray.length > 1)
        resultado = Number(value).toFixed(fixed);
    return resultado;
}