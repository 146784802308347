import { Axios } from "../config/axios"

const endpoint = "global/"

const getGlobalParameters = async (data: any) => {
    return await Axios().post(endpoint, data);
}


export const ApiGlobal = {
    getGlobalParameters,
}