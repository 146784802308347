import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import SSelect from "@controls/inputs/SSelect";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { useAppSelector } from "@redux/store/store";
import { ApiEmpresaDestino } from "@api/empresaDestino";
import { IEmpresaDestino } from "@interfaces/models/IEmpresaDestino";
import { EmpresaDestinoSaveListAction } from "@redux/actions/empresaDestinoActions";
import STextField from "@controls/inputs/STextField";
import { vigenteListSelect } from "@helpers/genericList";

interface IEmpresaDestinoEdit {
    handleClose: () => any;
    setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const EmpresaDestinoEdit = ({ handleClose, setResultAlert }: IEmpresaDestinoEdit) => {
    const distpatch = useDispatch();

    const { empresaDestinoSelected } = useAppSelector((store) => store.empresaDestino);
    const { regions: { regions, provincies, communes }, giros } = useAppSelector((state) => state.global);

    const form = useForm({ defaultValues: empresaDestinoSelected ?? {} });

    const { handleSubmit, watch } = form;

    const { regionId, provinciaId } = watch();

    const [inRequest, setInRequest] = useState<boolean>(false);

    const onSubmit = (data: IEmpresaDestino) => {
        setInRequest(true);
        ApiEmpresaDestino.PutUpdateEmpresaDestino(data.id, data).then((resp: any) => {
            setInRequest(false);
            if (resp.data.success) {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "success",
                });

                distpatch(EmpresaDestinoSaveListAction(resp.data.list));
            } else {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "error",
                });
            }
        });
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <STextField
                        name="rut"
                        form={form}
                        type="text"
                        label="Rut empresa"
                        mode="rut"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="descripcion"
                        form={form}
                        type="text"
                        label="Razón social"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="codigoInterno"
                        form={form}
                        type="text"
                        label="Código interno"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SSelect
                        label="Giro"
                        name="giroId"
                        form={form}
                        items={giros}
                        rules={{
                            required: "Este campo es requerido",
                        }}
                        native={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="correo"
                        form={form}
                        type="text"
                        label="Correo"
                        mode="email"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="direccion"
                        form={form}
                        type="text"
                        label="Dirección empresa"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="numeroDireccion"
                        form={form}
                        type="text"
                        label="Número de Calle"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="adicionalDireccion"
                        form={form}
                        type="text"
                        label="Dirección complementria (opcional)"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SSelect
                        label="Vigente"
                        name="vigente"
                        form={form}
                        items={vigenteListSelect}
                        defaultValue={""}
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SSelect
                        label="Región"
                        name="regionId"
                        form={form}
                        items={regions}
                        textField="descripcion"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SSelect
                        label="Provincia"
                        name="provinciaId"
                        form={form}
                        items={provincies}
                        textField="descripcion"
                        filterBy={{
                            value: regionId,
                            itemKey: "regionId",
                        }}
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SSelect
                        label="Comuna"
                        name="comunaId"
                        form={form}
                        items={communes}
                        textField="descripcion"
                        filterBy={{
                            value: provinciaId,
                            itemKey: "provinciaId",
                        }}
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
            </Grid>
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
            >
                Actualizar
            </LoadingButton>
            <Button fullWidth onClick={handleClose} variant="outlined">
                Cancel
            </Button>
        </Box>
    );
};
export default EmpresaDestinoEdit;
