
import { IGLOBAL_INIT_STATE } from "@interfaces/redux/IStorage";
import { types } from "../types/types";

const INIT_STATE: IGLOBAL_INIT_STATE = {
    loading: false,
    authentificatedUser: {},
    userGlobalParameters: {},
    regions: {
        regions: [],
        provincies: [],
        communes: []
    },
    rols: [],
    giros: [],
    loaded: false,
};

export const GlobalReducers = (
    state = INIT_STATE,
    action: any
): IGLOBAL_INIT_STATE => {
    switch (action.type) {
        case types.GLOBAL_INITING: {
            //Guardamos los nuevos datos necesarios para la sesion
            return {
                ...state,
                loading: true,
            };
        }
        case types.GLOBAL_LOGIN: {
            //Guardamos los nuevos datos necesarios para la sesion
            return {
                ...state,
                ...action.payload,
            };
        }
        case types.GLOBAL_PARAMETERS: {
            //Guardamos los nuevos datos necesarios para la sesion
            return {
                ...state,
                ...action.payload,
            };
        }
        case types.GLOBAL_INIT: {
            //Guardamos los nuevos datos necesarios para la sesion
            return {
                ...state,
                loaded: true,
                loading: false,
                ...action.payload,
            };
        }
        case types.GLOBAL_LOGOUT: {
            //Borrar token
            return INIT_STATE;
        }
        default:
            return state;
    }
};
