import { IUsuario } from "@interfaces/models/IUsuario";
import { types } from "../types/types";

export const UsuarioSave = (data: any) => ({
    type: types.USUARIOS_SAVE,
    payload: data
})

export const UsuariosSelectToEditAction = (data: IUsuario | null) => ({
    type: types.USUARIOS_SELECT_TO_EDIT,
    payload: data
})
