import React from "react";
import { Divider, List, ListSubheader } from "@mui/material";

import LayoutPrivateSidebarNavigationItem from "./LayoutPrivateSidebarNavigationItem";
import { useAppSelector } from "@redux/store/store";
import { INavigation } from "@interfaces/globalParameters/INavigation";

interface ILayoutPrivateSidebarNavigation {
  handleDrawerClose: () => any;
}
const LayoutPrivateSidebarNavigation = ({
  handleDrawerClose,
}: ILayoutPrivateSidebarNavigation) => {
  const {
    userGlobalParameters: { navigation },
  } = useAppSelector((store) => store.global);

navigation?.sort(function(a: INavigation,b: INavigation){
  return a.id-b.id
})

  return (
    <>
      {navigation?.map((n: INavigation) => (
        <React.Fragment key={n.id}>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {n.name}
              </ListSubheader>
            }
          >
            {n.items?.map((item) => (
              <LayoutPrivateSidebarNavigationItem
                {...item}
                handleDrawerClose={handleDrawerClose}
                key={item.id}
              />
            ))}
          </List>

          <Divider />
        </React.Fragment>
      ))}
    </>
  );
};

export default LayoutPrivateSidebarNavigation;
