
import { IEMPRESADESTINO_INIT_STATE } from "@interfaces/redux/IStorage";
import { types } from "../types/types";



const INIT_STATE: IEMPRESADESTINO_INIT_STATE = {
    empresaDestinoSelected: null,
    list: []
};

export const EmpresaDestinoReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.EMPRESADESTINO_SAVE_LIST: {
            return {
                ...state,
                list: action.payload,
                empresaDestinoSelected: null
            };
        }
        case types.EMPRESADESTINO_SELECT_TO_EDIT: {
            return {
                ...state,
                empresaDestinoSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
