import { ITRANSPORTE_INIT_STATE } from "@interfaces/redux/IStorage";
import { types } from "../types/types";

const INIT_STATE: ITRANSPORTE_INIT_STATE = {
  transporteSelected: null,
  list: [],
  selectBaseData: {
    conductor: [],
    camion: [],
    empresaTransportista: [],
    empresaDestino: [],
    empresaOrigen: [],
    tipoTraslado: [],
    tipoCarga: [],
    variedadCarga: [],
    acoplado: [],
    centrodecostos: [],
    direccionDestino: []
  },
};

export const transporteReducers = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case types.TRANSPORTE_INIT: {
        return {
          ...state,
          ...action.payload,
        };
      }
      
    case types.TRANSPORTE_SAVE_LIST: {
      return {
        ...state,
        list: action.payload,
        transporteSelected: null,
      };
    }
    case types.TRANSPORTE_SELECT_TO_EDIT: {
      return {
        ...state,
        transporteSelected: action.payload,
      };
    }
    
    default:
      return state;
  }
};
