import { Axios } from "@config/axios";
import { IVariedadCarga } from "@interfaces/models/IVariedadCarga";

const endpoint = "variedadCarga/";

const GetAllData = async () => {
  return await Axios().get(endpoint + "data");
};

const GetVariedadesCarga = async () => {
  return await Axios().get(endpoint);
};

const GetVariedadCarga = async (id: number) => {
  return await Axios().get(`${endpoint}${id}`);
};

const PostVariedadCarga = async (variedadesCarga: IVariedadCarga) => {
  return await Axios().post(endpoint, variedadesCarga);
};

const PutVariedadCarga = async (id: number, variedadesCarga: IVariedadCarga) => {
  return await Axios().put(`${endpoint}${id}`, variedadesCarga);
};

const DeleteVariedadCarga = async (id: number) => {
  return await Axios().delete(`${endpoint}${id}`);
};

export const ApiVariedadCarga = {
  GetAllData,
  GetVariedadesCarga,
  GetVariedadCarga,
  PostVariedadCarga,
  PutVariedadCarga,
  DeleteVariedadCarga,
};
