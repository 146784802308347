import { Axios } from "@config/axios";
import { ITipoCarga } from "@interfaces/models/ITipoCarga";

const endpoint = "tipoCarga/";

const GetTiposCarga = async () => {
  return await Axios().get(endpoint);
};

const GetTipoCarga = async (id: number) => {
  return await Axios().get(`${endpoint}${id}`);
};

const PostTipoCarga = async (tiposCarga: ITipoCarga) => {
  return await Axios().post(endpoint, tiposCarga);
};

const PutTipoCarga = async (id: number, tiposCarga: ITipoCarga) => {
  return await Axios().put(`${endpoint}${id}`, tiposCarga);
};

const DeleteTipoCarga = async (id: number) => {
  return await Axios().delete(`${endpoint}${id}`);
};

export const ApiTipoCarga = {
  GetTiposCarga,
  GetTipoCarga,
  PostTipoCarga,
  PutTipoCarga,
  DeleteTipoCarga,
};
