import { Snackbar } from "@mui/material";
import SAlert from "./SAlert";
import { TSSnackbarAlert } from "./types/TSSnackbarAlert";

const SSnackBarAlert = ({
    state,
    setState
}: TSSnackbarAlert) => {
    const Close = () => setState({message: ""})
    return (
        <Snackbar
            open={!!state.message}
            autoHideDuration={6000}
            onClose={Close}
            anchorOrigin={{
                horizontal: "right",
                vertical: "top"
            }}
        >
            <SAlert onClose={Close} severity={state.severity} sx={{ width: "100%" }}>
                {state.message}
            </SAlert>
        </Snackbar>
    );
};

export default SSnackBarAlert;
