
import { IDIRECCIONDESTINO_INIT_STATE } from "@interfaces/redux/IStorage";
import { types } from "../types/types";



const INIT_STATE: IDIRECCIONDESTINO_INIT_STATE = {
    direccionSelected: null,
    list: [],
    selectEmpresasDestino: []
};

export const direccionDestinoReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.DIRECCION_INIT: {
            return {
                ...state, 
                ...action.payload,
            };
        }
        case types.DIRECCION_SAVE_LIST: {
            return {
                ...state,
                list: action.payload,
                direccionSelected: null
            };
        }
        case types.DIRECCION_SELECT_TO_EDIT: {
            return {
                ...state,
                direccionSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
