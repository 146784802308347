import { ITipoCarga } from "@interfaces/models/ITipoCarga";
import { types } from "../types/types";

export const TipoCargaSaveListAction = (data: ITipoCarga[]) => ({
    type: types.TIPOCARGA_SAVE_LIST,
    payload: data
})

export const TipoCargaSelectToEditAction = (data: ITipoCarga | null) => ({
    type: types.TIPOCARGA_SELECT_TO_EDIT,
    payload: data
})
