import { IModelo } from "@interfaces/models/IModelo";
import { types } from "../types/types";

export const ModeloSaveListAction = (data: IModelo[]) => ({
    type: types.MODELO_SAVE_LIST,
    payload: data
})

export const ModeloSelectToEditAction = (data: IModelo | null) => ({
    type: types.MODELO_SELECT_TO_EDIT,
    payload: data
})
