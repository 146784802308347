import { Box, Button } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@redux/store/store";
import SDataGrid from "@controls/tables/SDataGrid";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ConductoresEdit from "./ConductoresEdit";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { IConductor } from "@interfaces/models/IConductor";
import {
  ConductoresSaveListAction,
  ConductoresSelectToEditAction,
} from "@redux/actions/conductoresActions";
import { ApiConductor } from "@api/conductor";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";

const ConductoresList = () => {
  const distpatch = useDispatch();

  const { list, conductorSelected } = useAppSelector(
    (state) => state.conductores  
  );

  const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});

  const Edit = (selected: IConductor) => {
    distpatch(ConductoresSelectToEditAction(selected));
  };

  const handleClose = () => {
    distpatch(ConductoresSelectToEditAction(null));
  };

  const columns: GridColDef[] = [
    {
      field: "rut",
      headerName: "Rut",
      flex: 3,
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 4,
    },
    {
      field: "correo",
      headerName: "Correo",
      flex: 4,
    },
    {
      field: "telefono",
      headerName: "Teléfono",
      flex: 3,
    },
    {
      field: "contratado",
      headerName: "Contratado",
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <>{params.row.contratado ? "Sí" : "No"}</>
      ),
    },
    {
      field: "id",
      headerName: "Acción",
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <Button color="warning" onClick={() => Edit(params.row)}>
          Editar
        </Button>
      ),
    },
  ];

  useEffect(() => {
    ApiConductor.GetConductores().then((resp: any) => {
      distpatch(ConductoresSaveListAction(resp.data));
    });
    // eslint-disable-next-line
  }, []);

  if (!list) return <></>;

  return (
    <Box sx={{ height: 400, width: "95%", ml: "20px", mr: "20px" }}>
      <SDataGrid rows={list} columns={columns} disableSelectionOnClick />

      <Dialog open={!!conductorSelected} onClose={handleClose}>
        <DialogTitle>Editar Conductor</DialogTitle>
        <DialogContent>
          <ConductoresEdit
            handleClose={handleClose}
            setResultAlert={setResultAlert}
          />
        </DialogContent>
      </Dialog>

      <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
    </Box>
  );
};
export default ConductoresList;
