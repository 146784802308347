import EmpresaTransportistaCreate from "./partial/EmpresaTransportistaCreate";
import EmpresaTransportistaList from "./partial/EmpresaTransportistaList";

const EmpresaTransportista = () => {
    
    return (
        <div className="container">
            <EmpresaTransportistaCreate />
            <EmpresaTransportistaList />
        </div>
    );
};
export default EmpresaTransportista;
