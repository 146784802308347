import { IAcoplado } from "@interfaces/models/IAcoplado";
import { Axios } from "../config/axios";

const endpoint = "acoplado/";

const GetAcoplados = async () => {
  return await Axios().get(endpoint);
};

const GetAcoplado = async (id: number) => {
  return await Axios().get(`${endpoint}${id}`);
};

const PostAcoplado = async (acoplado: IAcoplado) => {
  return await Axios().post(endpoint, acoplado);
};

const PutAcoplado = async (id: number, acoplado: IAcoplado) => {
  return await Axios().put(`${endpoint}${id}`, acoplado);
};

const DeleteAcoplado = async (id: number) => {
  return await Axios().delete(`${endpoint}${id}`);
};

export const ApiAcoplado = {
  GetAcoplados,
  GetAcoplado,
  PostAcoplado,
  PutAcoplado,
  DeleteAcoplado,
};
