import { IBaseResponse } from "@interfaces/models/IBaseResponse";
import { Axios } from "../config/axios";
import { ICentroCostos } from "../interfaces/models/ICentroCostos";
import { AxiosResponse } from "axios";

const endpoint = "CentroCostos/";

const GetAll = async () => {
    return await Axios().get<any, AxiosResponse<IBaseResponse<ICentroCostos[]>, any>>(endpoint);
};

const Get = async (id: number) => {
    return await Axios().get<any, AxiosResponse<IBaseResponse<ICentroCostos>, any>>(`${endpoint}${id}`);
};

const Post = async (CentroCosto: ICentroCostos) => {
    return await Axios().post<any, AxiosResponse<IBaseResponse<ICentroCostos>, any>>(endpoint, CentroCosto);
};

const Put = async (id: number, CentroCosto: ICentroCostos) => {
    return await Axios().put<any, AxiosResponse<IBaseResponse<ICentroCostos>, any>>(`${endpoint}${id}`, CentroCosto);
};

const Delete = async (id: number) => {
    return await Axios().delete<any, AxiosResponse<IBaseResponse<ICentroCostos>, any>>(`${endpoint}${id}`);
};

export const ApiCentroCosto = {
    GetAll,
    Get,
    Post,
    Put,
    Delete
};
