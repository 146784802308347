
export const types =
{
    //GLOBAL
    GLOBAL_LOGIN: "GLOBAL_LOGIN",
    GLOBAL_INITING: "GLOBAL_INITING",
    GLOBAL_PARAMETERS: "GLOBAL_PARAMETERS",
    GLOBAL_INIT: "GLOBAL_INIT",
    GLOBAL_LOGOUT: "GLOBAL_LOGOUT",

    //USUARIOS
    USUARIOS_SAVE: "USUARIOS_SAVE",
    USUARIOS_SELECT_TO_EDIT: "USUARIOS_SELECT_TO_EDIT",

    //ACOPLADOS
    ACOPLADOS_SAVE_LIST: "ACOPLADOS_SAVE_LIST",
    ACOPLADOS_SELECT_TO_EDIT: "ACOPLADOS_SELECT_TO_EDIT",

    //CAMIONES
    CAMIONES_INIT: "CAMIONES_INIT",
    CAMIONES_SAVE_LIST: "CAMIONES_SAVE_LIST",
    CAMIONES_SELECT_TO_EDIT: "CAMIONES_SELECT_TO_EDIT",

    //MARCA
    MARCA_SAVE_LIST: "MARCA_SAVE_LIST",
    MARCA_SELECT_TO_EDIT: "MARCA_SELECT_TO_EDIT",

    //MODELO
    MODELO_SAVE_LIST: "MODELO_SAVE_LIST",
    MODELO_SELECT_TO_EDIT: "MODELO_SELECT_TO_EDIT",

    //EMPRESATRANSPORTISTA
    EMPRESATRANSPORTISTA_SAVE_LIST: "EMPRESATRANSPORTISTA_SAVE_LIST",
    EMPRESATRANSPORTISTA_SELECT_TO_EDIT: "EMPRESATRANSPORTISTA_SELECT_TO_EDIT",

    //CONDUCTORES
    CONDUCTORES_SAVE_LIST: "CONDUCTORES_SAVE_LIST",
    CONDUCTORES_SELECT_TO_EDIT: "CONDUCTORES_SELECT_TO_EDIT",

    //EMPRESADESTINO
    EMPRESADESTINO_SAVE_LIST: "EMPRESADESTINO_SAVE_LIST",
    EMPRESADESTINO_SELECT_TO_EDIT: "EMPRESADESTINO_SELECT_TO_EDIT",

    // TRANSPORTE
    TRANSPORTE_INIT: "TRANSPORTE_INIT",
    TRANSPORTE_SAVE_LIST: "TRANSPORTE_SAVE_LIST",
    TRANSPORTE_SELECT_TO_EDIT: "TRANSPORTE_SELECT_TO_EDIT",

    //TIPOSCARGA
    TIPOCARGA_SAVE_LIST: "TIPOCARGA_SAVE_LIST",
    TIPOCARGA_SELECT_TO_EDIT: "TIPOCARGA_SELECT_TO_EDIT",

    //VARIEDADESCARGA
    VARIEDADCARGA_INIT: "VARIEDADCARGA_INIT",
    VARIEDADCARGA_SAVE_LIST: "VARIEDADCARGA_SAVE_LIST",
    VARIEDADCARGA_SELECT_TO_EDIT: "VARIEDADCARGA_SELECT_TO_EDIT",

    //DIRECCIONES
    DIRECCION_INIT: "DIRECCION_INIT",
    DIRECCION_SAVE_LIST: "DIRECCION_SAVE_LIST",
    DIRECCION_SELECT_TO_EDIT: "DIRECCION_SELECT_TO_EDIT",

    //CENTROCOSTOS
    CENTROCOSTOS_SAVE_LIST: "CENTROCOSTOS_SAVE_LIST",
    CENTROCOSTOS_SELECT_TO_EDIT: "CENTROCOSTOS_SELECT_TO_EDIT",

}