import LoadingPage from "@controls/util/LoadingPage";
import { CamionesInitAction } from "@redux/actions/camionActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CamionesCreate from "./partial/CamionesCreate";
import CamionesList from "./partial/CamionesList";
import { ApiCamion } from "@api/camion";

const Camion = () => {
    

    const [loaded, setLoaded] = useState(false);

    const distpatch = useDispatch();

    useEffect(() => {
        ApiCamion.GetAllData().then((resp: any) => {
            distpatch(CamionesInitAction(resp.data.data));
            setLoaded(true);
        });
        // eslint-disable-next-line
    }, []);

    if (!loaded) return <LoadingPage/>;

    return (
        <div className="container">
            <CamionesCreate />
            <CamionesList />
        </div>
    );
};

export default Camion;
