import { Axios } from "@config/axios"
import { IMarca } from "@interfaces/models/IMarca";


const endpoint = "marca/"

const GetAllMarca = async () => {
    return await Axios().get(endpoint);
}

const GetMarca = async (id: number) => {
    return await Axios().get(`${endpoint}${id}`);
}

const PostCreateMarca = async (marca: IMarca) => {
    return await Axios().post(endpoint, marca);
}

const PutUpdateMarca = async (id: number, marca: IMarca) => {
    return await Axios().put(`${endpoint}${id}`, marca);
}

const DeleteMarca = async (id: number) => {
    return await Axios().delete(`${endpoint}${id}`);
}

export const ApiMarca = {
    GetAllMarca,
    GetMarca,
    PostCreateMarca,
    PutUpdateMarca,
    DeleteMarca
}