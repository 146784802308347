import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, InputAdornment } from '@mui/material';
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApiTipoCarga } from "@api/tipocarga";
import { ITipoCarga } from "@interfaces/models/ITipoCarga";
import { TipoCargaSaveListAction } from "@redux/actions/tiposCargaActions";
import { useAppSelector } from "@redux/store/store";
import STextField from "@controls/inputs/STextField";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

interface ITipoCargaEdit {
  handleClose: () => any;
  setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const TipoCargaEdit = ({ handleClose, setResultAlert }: ITipoCargaEdit) => {
  const distpatch = useDispatch();

  const { tipoCargaSelected } = useAppSelector((store) => store.tiposCarga);

  const form = useForm({ defaultValues: tipoCargaSelected ?? {} });

  const { handleSubmit } = form;

  const [inRequest, setInRequest] = useState<boolean>(false);

  const onSubmit = (data: ITipoCarga) => {
    setInRequest(true);
    ApiTipoCarga.PutTipoCarga(data.id, data).then((resp: any) => {
      setInRequest(false);
      if (resp.data.success) {
        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(TipoCargaSaveListAction(resp.data.list));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <STextField
            name="descripcion"
            form={form}
            type="text"
            label="Nombre de Tipo Carga"
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            name="codigo"
            form={form}
            type="text"
            label="Código"
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            name="costo"
            form={form}
            mode="decimal"
            label="Costo"
            rules={{
              required: "Este campo es requerido",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={inRequest}
      >
        Actualizar
      </LoadingButton>
      <Button fullWidth onClick={handleClose} variant="outlined">
        Cancel
      </Button>
    </Box>
  );
};
export default TipoCargaEdit;
