import { Chip, Grid, InputAdornment, Typography } from "@mui/material";
import Form from "react-hook-form";
import SSelect from "@controls/inputs/SSelect";
import { useAppSelector } from "@redux/store/store";
import { PesoGuiaListSelect } from "@helpers/genericList";
import STextField from "@controls/inputs/STextField";
import { useEffect, useState } from "react";
interface ITransportFormItem {
    form: Form.UseFormReturn<any>;
}

const TransportFormItem = (props: ITransportFormItem) => {
    const { form } = props;

    const { watch, resetField, setValue } = form;

    const [otherSelected, setOtherSelected] = useState<boolean>(false);

    const {
        selectBaseData: { tipoCarga, variedadCarga },
    } = useAppSelector((state) => state.transporte);

    const { tipocargaId, sumaPesoGuia, otroPesoGuia } = watch();

    useEffect(() => {
        if (!!tipocargaId) {
            let tipoCargaCosto = tipoCarga.find((tc) => tc.id === tipocargaId);
            let temporalGuia = 0;
            if (sumaPesoGuia === -1) {

                temporalGuia = Number(otroPesoGuia) * Number(tipoCargaCosto?.costo);

                temporalGuia = Math.round(temporalGuia);

                const ivaFlete = Math.round(temporalGuia * 0.19);

                setValue("costoFlete", temporalGuia);

                setValue("costoIvaFlete", ivaFlete);

                setValue("costoTotalFlete", temporalGuia + ivaFlete);
            } else {
                temporalGuia = Number(sumaPesoGuia) * Number(tipoCargaCosto?.costo);

                temporalGuia = Math.round(temporalGuia);

                const ivaFlete = Math.round(temporalGuia * 0.19);

                setValue("costoFlete", temporalGuia);

                setValue("costoIvaFlete", ivaFlete);

                setValue("costoTotalFlete", temporalGuia + ivaFlete);
            }
        } else {
            setValue("costoFlete", "");
            setValue("costoIvaFlete", "");
            setValue("costoTotalFlete", "");
        }
        // eslint-disable-next-line
    }, [tipocargaId, sumaPesoGuia, otroPesoGuia]);

    useEffect(() => {
        setOtherSelected(sumaPesoGuia === -1);
        resetField("otroPesoGuia");
        // eslint-disable-next-line
    }, [sumaPesoGuia]);

    return (
        <Grid container spacing={2} style={{ marginTop: "15px" }}>
            <Grid item xs={12}>
                <Typography component="h3" variant="h6">
                    <Chip label="3" color="success" size="small" /> Detalle carga
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Tipo Carga"
                    name="tipocargaId"
                    form={form}
                    items={tipoCarga}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Variedad Carga"
                    name="variedadcargaId"
                    form={form}
                    items={variedadCarga}
                    defaultValue={""}
                    filterBy={{
                        value: tipocargaId,
                        itemKey: "tipocargaId",
                    }}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SSelect
                    name="sumaPesoGuia"
                    label="Peso Guía"
                    form={form}
                    placeholder="Suma Peso Guía"
                    items={PesoGuiaListSelect}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                {otherSelected && (
                    <STextField
                        name="otroPesoGuia"
                        label="Nuevo peso guía"
                        form={form}
                        placeholder="Suma Peso Guía"
                        mode="thousandsSeparator"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                <Typography component="h6" variant="subtitle2">
                    <Chip variant="outlined" label="$" color="success" size="small" /> Precio
                </Typography>
            </Grid>

            {/* Esto es total guia? */}
            <Grid item xs={12} sm={4}>
                <STextField
                    name="costoFlete"
                    form={form}
                    label="NETO"
                    placeholder="NETO"
                    disabled
                    mode="thousandsSeparator"
                    rules={{
                        required: "Este campo es requerido",
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <STextField
                    name="costoIvaFlete"
                    form={form}
                    label="IVA"
                    placeholder="IVA"
                    disabled
                    mode="thousandsSeparator"
                    rules={{
                        required: "Este campo es requerido",
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <STextField
                    name="costoTotalFlete"
                    form={form}
                    label="TOTAL"
                    placeholder="TOTAL"
                    disabled
                    mode="thousandsSeparator"
                    rules={{
                        required: "Este campo es requerido",
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default TransportFormItem;
