
import { IEMPRESATRANSPORTISTA_INIT_STATE } from "../../interfaces/redux/IStorage";
import { types } from "../types/types";



const INIT_STATE: IEMPRESATRANSPORTISTA_INIT_STATE = {
    empresaTransportistaSelected: null,
    list: []
};

export const EmpresaTransportistaReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.EMPRESATRANSPORTISTA_SAVE_LIST: {
            return {
                ...state,
                list: action.payload,
                empresaTransportistaSelected: null
            };
        }
        case types.EMPRESATRANSPORTISTA_SELECT_TO_EDIT: {
            return {
                ...state,
                empresaTransportistaSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
