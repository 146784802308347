import { IUSUARIOS_INIT_STATE } from "../../interfaces/redux/IStorage";
import { types } from "../types/types";



const INIT_STATE: IUSUARIOS_INIT_STATE = {
    usuarioSelected: null,
    list: []
};

export const UsuariosReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.USUARIOS_SAVE: {
            return {
                ...state,
                list: action.payload,
                usuarioSelected: null
            };
        }
        case types.USUARIOS_SELECT_TO_EDIT: {
            return {
                ...state,
                usuarioSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
