import MarcasCreate from "./partial/MarcasCreate";
import MarcasList from "./partial/MarcasList";

const Marca = () => {

  return (
    <div className="container">
      <MarcasCreate />
      <MarcasList />
    </div>
  );
};
export default Marca;
