import { IMODELO_INIT_STATE } from "@interfaces/redux/IStorage";
import { types } from "../types/types";


const INIT_STATE: IMODELO_INIT_STATE = {
    modeloSelected: null,
    list: []
};

export const ModelosReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.MODELO_SAVE_LIST: {
            return {
                ...state,
                list: action.payload,
                modeloSelected: null
            };
        }
        case types.MODELO_SELECT_TO_EDIT: {
            return {
                ...state,
                modeloSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
