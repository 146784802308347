import { IEmpresaTransportista } from "@interfaces/models/IEmpresaTransportista";
import { types } from "../types/types";

export const EmpresaTransportistaSaveListAction = (data: IEmpresaTransportista[]) => ({
    type: types.EMPRESATRANSPORTISTA_SAVE_LIST,
    payload: data
})

export const EmpresaTransportistaSelectToEditAction = (data: IEmpresaTransportista | null) => ({
    type: types.EMPRESATRANSPORTISTA_SELECT_TO_EDIT,
    payload: data
})
