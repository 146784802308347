import { DatePicker, DatePickerProps } from "@mui/lab";
import { FormHelperText, TextField } from "@mui/material";
import Form, { Controller } from "react-hook-form";

type TProps = Omit<DatePickerProps, "value" | "onChange" | "renderInput"> &
    React.RefAttributes<HTMLDivElement> & {
        name: string;
        form: Form.UseFormReturn<any>;
        rules?: Form.RegisterOptions;
        renderInput?: any;
        value?: any;
        onChange?: any;
        render?: any;
    };

const SDatePicker = (props: TProps) => {
    const {
        formState: { errors },
        control,
    } = props.form;

    return (
        <Controller
            control={control}
            name={props.name}
            rules={{ ...props.rules}}
            render={({ field: { ref, ...rest } }) => (
                <DatePicker
                    label={props.label}
                    {...rest}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                sx={{
                                    margin: "5px",
                                    padding: "0",
                                }}
                                fullWidth
                            />
                            <FormHelperText id={props.name + "-error-text"} error>
                                {errors[props.name]?.message}
                            </FormHelperText>
                        </>
                    )}
                />
            )}
        />
    );
};

export default SDatePicker;
