import { IConductor } from "@interfaces/models/IConductor";
import { Axios } from "../config/axios"

const endpoint = "conductor/"

const GetConductores = async () => {
    return await Axios().get(endpoint);
}

const GetConductor = async (id: number) => {
    return await Axios().get(`${endpoint}${id}`);
}

const PostConductor = async (conductor: IConductor) => {
    return await Axios().post(endpoint, conductor);
}

const PutConductor = async (id: number, conductor: IConductor) => {
    return await Axios().put(`${endpoint}${id}`, conductor);
}

const DeleteConductor = async (id: number) => {
    return await Axios().delete(`${endpoint}${id}`);
}

export const ApiConductor = {
    GetConductores,
    GetConductor,
    PostConductor,
    PutConductor,
    DeleteConductor,
}