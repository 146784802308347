import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import dateFnsEs from "date-fns/locale/es";
import { Provider } from "react-redux";
import Routes from "./routes/Routes";
import { store } from "./ts/redux/store/store";

function App() {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={dateFnsEs}>
			<Provider store={store}>
				<Routes />
			</Provider>
		</LocalizationProvider>
	);
}

export default App;
