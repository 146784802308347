import { IEmpresaTransportista } from "@interfaces/models/IEmpresaTransportista";
import { Axios } from "../config/axios"


const endpoint = "empresaTransportista/"

const GetEmpresasTransportista = async () => {
    return await Axios().get(endpoint);
}

const GetEmpresaTransportista = async (id: number) => {
    return await Axios().get(`${endpoint}${id}`);
}

const PostCreateEmpresaTransportista = async (empresaTransportista: IEmpresaTransportista) => {
    return await Axios().post(endpoint, empresaTransportista);
}

const PutUpdateEmpresaTransportista = async (id: number, empresaTransportista: IEmpresaTransportista) => {
    return await Axios().put(`${endpoint}${id}`, empresaTransportista);
}

const DeleteEmpresaTransportista = async (id: number) => {
    return await Axios().delete(`${endpoint}${id}`);
}

export const ApiEmpresaTransportista = {
    GetEmpresasTransportista,
    GetEmpresaTransportista,
    PostCreateEmpresaTransportista,
    PutUpdateEmpresaTransportista,
    DeleteEmpresaTransportista
}