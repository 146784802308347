import { Box, Button } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@redux/store/store";
import SDataGrid from "@controls/tables/SDataGrid";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

import { ApiEmpresaTransportista } from "@api/empresaTransportista";

import {
    EmpresaTransportistaSaveListAction,
    EmpresaTransportistaSelectToEditAction,
} from "@redux/actions/empresacamionActions";
import { IEmpresaTransportista } from "@interfaces/models/IEmpresaTransportista";
import EmpresaTransportistaEdit from "./EmpresaTransportistaEdit";

const EmpresaTransportistaList = () => {
    const distpatch = useDispatch();

    const { list, empresaTransportistaSelected } = useAppSelector(
        (state) => state.empresaTransportista
    );
    const { regions: { provincies, communes }  } = useAppSelector((state) => state.global);

    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});

    const Edit = (selected: IEmpresaTransportista) => {
        let companySelect = {...selected};

        const communeSelected = communes.find(c => c.id === companySelect.comunaId)
        const provinceSelected = provincies.find(c => c.id === communeSelected?.provinciaId)

        companySelect.regionId = provinceSelected?.regionId ?? 0;
        companySelect.provinciaId = provinceSelected?.id ?? 0;

        distpatch(EmpresaTransportistaSelectToEditAction(companySelect));
    };

    const handleClose = () => {
        distpatch(EmpresaTransportistaSelectToEditAction(null));
    };

    const columns: GridColDef[] = [
        {
            field: "rut",
            headerName: "Rut",
            flex: 3,
        },
        {
            field: "descripcion",
            headerName: "Descripción",
            flex: 3,
        },
        {
            field: "correo",
            headerName: "Correo",
            flex: 3,
        },
        {
            field: "vigente",
            headerName: "Vigente",
            flex: 3,
            renderCell: (params: GridValueGetterParams) => (
                <>{params.row.vigente ? "Sí" : "No"}</>
            ),
        },
        {
            field: "comunaDesc",
            headerName: "Comuna",
            flex: 3
        },
        {
            field: "id",
            headerName: "Acción",
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: GridValueGetterParams) => (
                <Button color="warning" onClick={() => Edit(params.row)}>
                    Editar
                </Button>
            ),
        },
    ];

    useEffect(() => {
        ApiEmpresaTransportista.GetEmpresasTransportista().then((resp: any) => {
            distpatch(EmpresaTransportistaSaveListAction(resp.data));
        });
        // eslint-disable-next-line
    }, []);

    if (!list) return <></>;

    return (
        <Box sx={{ height: 400, width: "95%", ml: "20px", mr: "20px" }}>
            <SDataGrid rows={list} columns={columns} disableSelectionOnClick />

            <Dialog open={!!empresaTransportistaSelected} onClose={handleClose}>
                <DialogTitle>Editar Empresa Transportista</DialogTitle>
                <DialogContent>
                    <EmpresaTransportistaEdit
                        handleClose={handleClose}
                        setResultAlert={setResultAlert}
                    />
                </DialogContent>
            </Dialog>

            <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
        </Box>
    );
};
export default EmpresaTransportistaList;
