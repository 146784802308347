import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { useAppSelector } from "@redux/store/store";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { ApiTransporte } from "@api/transporte";
import { TransporteSaveAction } from "@redux/actions/transporteActions";
import { useDispatch } from "react-redux";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { LoadingButton } from "@mui/lab";
import { DevTool } from "@hookform/devtools";

import TransportFormTransport from "./form/TransportFormTransport";
import TransportFormBilling from "./form/TransportFormBilling";
import TransportFormItem from "./form/TransportFormItem";
import { Card, CardContent } from "@mui/material";
import TransportAditionalDetail from "./form/TransportAditionalDetail";

export const TransportForm = () => {
  const distpatch = useDispatch();

  // Sacar la id usuario para el post
  const {
    authentificatedUser: { id },
  } = useAppSelector((store) => store.global);

  const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
  const [inRequest, setInRequest] = useState<boolean>(false);

  const form = useForm();
  const { handleSubmit, control } = form;

  const onSubmit = (data: any) => {
    setInRequest(true);
    // Agregar id Usuario  fechaPlanificada: FechaPlanificada
    data = { ...data, usuarioId: id };
    ApiTransporte.PostTransporte(data).then((resp: any) => {
      setInRequest(false);
      if (resp.data.success) {
        form.reset({});
        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });
        distpatch(TransporteSaveAction(resp.data.list));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });
  };

  return (
    <>
      <DevTool control={control} />
      <Container component="main">
        <CssBaseline />
        <Box>
          <Card>
            <CardContent style={{ padding: "40px" }}>
              <Typography component="h1" variant="h4">
                Orden de transporte
              </Typography>
              <br />
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 3, width: "100%" }}
              >
                <Grid container spacing={2}>
                  <TransportFormTransport form={form} />

                  <TransportFormBilling form={form} />

                  <TransportFormItem form={form} />
                  
                  <TransportAditionalDetail form={form} />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    loading={inRequest}
                  >
                    Generar y enviar
                  </LoadingButton>
                </Grid>
              </Box>
            </CardContent>{" "}
          </Card>
        </Box>

        <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
      </Container>
    </>
  );
};
