import { LoadingButton } from "@mui/lab";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

import { ApiEmpresaDestino } from "@api/empresaDestino";
import { EmpresaDestinoSaveListAction } from "@redux/actions/empresaDestinoActions";
import SSelect from "@controls/inputs/SSelect";
import { useAppSelector } from "@redux/store/store";

const EmpresaDestinoCreate = () => {
    const distpatch = useDispatch();

    const { regions: { regions, provincies, communes }, giros } = useAppSelector((state) => state.global);

    const form = useForm({ mode: "onChange" });

    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
    const [inRequest, setInRequest] = useState<boolean>(false);

    const { handleSubmit, watch } = form;

    const { regionId, provinciaId } = watch();

    const onSubmit = (data: any) => {
        setInRequest(true);

        ApiEmpresaDestino.PostCreateEmpresaDestino(data).then((resp: any) => {
            setInRequest(false);
            if (resp.data.success) {
                form.reset({});

                setResultAlert({
                    message: resp.data?.message,
                    severity: "success",
                });

                distpatch(EmpresaDestinoSaveListAction(resp.data.list));
            } else {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "error",
                });
            }
        });
    };

    return (
        <Container component="main">
            <Box>
                <Typography component="h1" variant="h4">
                    Formulario Empresa de Destino
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="rut"
                                form={form}
                                type="text"
                                label="Rut empresa"
                                mode="rut"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="descripcion"
                                form={form}
                                type="text"
                                label="Razón social"
                                mode="nombreempresa"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="codigoInterno"
                                form={form}
                                type="text"
                                label="Código interno"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SSelect
                                label="Giro"
                                name="giroId"
                                form={form}
                                items={giros}
                                defaultValue={""}
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                                native={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="correo"
                                form={form}
                                type="text"
                                label="Correo"
                                mode="email"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>  
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="direccion"
                                form={form}
                                type="text"
                                label="Dirección empresa"
                                mode="nombreempresa"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="numeroDireccion"
                                form={form}
                                type="text"
                                label="Número de Calle"
                                mode="number"
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>                        
                        <Grid item xs={12} sm={4}>
                            <STextField
                                name="adicionalDireccion"
                                form={form}
                                type="text"
                                label="Dirección complementaria (opcional)"
                                mode="nombreempresa"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SSelect
                                label="Región"
                                name="regionId"
                                form={form}
                                items={regions}
                                textField="descripcion"
                                defaultValue={""}
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SSelect
                                label="Provincia"
                                name="provinciaId"
                                form={form}
                                items={provincies}
                                textField="descripcion"
                                defaultValue={""}
                                filterBy={{
                                    value: regionId,
                                    itemKey: "regionId",
                                }}
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SSelect
                                label="Comuna"
                                name="comunaId"
                                form={form}
                                items={communes}
                                textField="descripcion"
                                filterBy={{
                                    value: provinciaId,
                                    itemKey: "provinciaId",
                                }}
                                defaultValue={""}
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} display="flex" justifyContent="flex-end">
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                loading={inRequest}
                            >
                                Guardar Empresa
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
        </Container>
    );
};

export default EmpresaDestinoCreate;
