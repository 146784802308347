import { IEmpresaDestino } from "@interfaces/models/IEmpresaDestino";
import { Axios } from "../config/axios"

const endpoint = "empresaDestino/"

const GetEmpresasDestino = async () => {
    return await Axios().get(endpoint);
}

const GetEmpresaDestino = async (id: number) => {
    return await Axios().get(`${endpoint}${id}`);
}

const PostCreateEmpresaDestino = async (empresa: IEmpresaDestino) => {
    return await Axios().post(endpoint, empresa);
}

const PutUpdateEmpresaDestino = async (id: number, empresa: IEmpresaDestino) => {
    return await Axios().put(`${endpoint}${id}`, empresa);
}

const DeleteEmpresaDestino = async (id: number) => {
    return await Axios().delete(`${endpoint}${id}`);
}

export const ApiEmpresaDestino = {
    GetEmpresasDestino,
    GetEmpresaDestino,
    PostCreateEmpresaDestino,
    PutUpdateEmpresaDestino,
    DeleteEmpresaDestino
}