import { Axios } from "../config/axios";
import { ICamion } from "../interfaces/models/ICamion";

const endpoint = "camion/";

const GetAllData = async () => {
    return await Axios().get(endpoint + "data");
};

const GetCamiones = async () => {
    return await Axios().get(endpoint);
};

const GetCamion = async (id: number) => {
    return await Axios().get(`${endpoint}${id}`);
};

const PostCamion = async (camion: ICamion) => {
    return await Axios().post(endpoint, camion);
};

const PutCamion = async (id: number, camion: ICamion) => {
    return await Axios().put(`${endpoint}${id}`, camion);
};

const DeleteCamion = async (id: number) => {
    return await Axios().delete(`${endpoint}${id}`);
};

export const ApiCamion = {
    GetAllData,
    GetCamiones,
    GetCamion,
    PostCamion,
    PutCamion,
    DeleteCamion
};
