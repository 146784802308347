import { LoadingButton } from "@mui/lab";
import { Container, Grid, Typography } from '@mui/material';
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CentroCostosSaveListAction } from "@redux/actions/centroCostosActions";
import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { ApiCentroCosto } from "@api/centroCostos";
import { useAppSelector } from "@redux/store/store";

const TipoCargaCreate = () => {
  const distpatch = useDispatch();
  const { list } = useAppSelector((state) => state.centroCostos);
  const form = useForm({ mode: "onChange" });

  const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
  const [inRequest, setInRequest] = useState<boolean>(false);

  const { handleSubmit } = form;

  const onSubmit = (data: any) => {
    setInRequest(true);
    ApiCentroCosto.Post(data).then((resp) => {
      setInRequest(false);
      if (resp.data.success) {
        form.reset({});

        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(CentroCostosSaveListAction([resp.data.data, ...list ?? []]));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });

  };

  return (
    <Container component="main">
      <Box>
        <Typography component="h1" variant="h4">
          Formulario Centro de costo
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <STextField
                name="descripcion"
                form={form}
                type="text"
                label="Nombre centro costos"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <STextField
                name="codigo"
                form={form}
                type="text"
                label="Código"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
              >
                Guardar Centro de costos
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
    </Container>
  );
};

export default TipoCargaCreate;
