import { LoadingButton } from "@mui/lab";
import { Checkbox, Container, FormControlLabel, Grid, Typography, InputAdornment } from '@mui/material';
import { Box } from "@mui/system";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApiTipoCarga } from "@api/tipocarga";
import { TipoCargaSaveListAction } from "@redux/actions/tiposCargaActions";
import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";

const TipoCargaCreate = () => {
  const distpatch = useDispatch();

  const form = useForm({ mode: "onChange" });

  const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
  const [inRequest, setInRequest] = useState<boolean>(false);

  const { handleSubmit, control } = form;

  const onSubmit = (data: any) => {
    setInRequest(true);
    ApiTipoCarga.PostTipoCarga(data).then((resp: any) => {
      setInRequest(false);
      if (resp.data.success) {
        form.reset({});

        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(TipoCargaSaveListAction(resp.data.list));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });

  };

  return (
    <Container component="main">
      <Box>
        <Typography component="h1" variant="h4">
          Formulario Tipo de Carga
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <STextField
                name="descripcion"
                form={form}
                type="text"
                label="Nombre Tipo de Carga"
                mode="descripciones"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <STextField
                name="codigo"
                form={form}
                type="text"
                label="Código"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <STextField
                name="costo"
                form={form}
                mode="decimal"
                label="Costo"
                rules={{
                  required: "Este campo es requerido"
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Controller
                    name="checkbox"
                    control={control}
                    defaultValue={false}
                    render={({ field: { value, ref, ...field } }) => (
                      <Checkbox
                        {...field}
                        inputRef={ref}
                        checked={!!value}
                        color="primary"
                        size={"medium"}
                        disableRipple
                      />
                    )}
                  />
                }
                label="Agregar Variedad de carga por defecto."
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
              >
                Guardar Tipo de Carga
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
    </Container>
  );
};

export default TipoCargaCreate;
