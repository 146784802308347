import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@redux/store/store";
import STextField from "@controls/inputs/STextField";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { ApiEmpresaTransportista } from "@api/empresaTransportista";
import { EmpresaTransportistaSaveListAction } from "@redux/actions/empresacamionActions";
import { IEmpresaTransportista } from "@interfaces/models/IEmpresaTransportista";
import SSelect from "@controls/inputs/SSelect";
import { vigenteListSelect } from "@helpers/genericList";

interface IEmpresaTransportistaEdit {
    handleClose: () => any;
    setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const EmpresaTransportistaEdit = ({
    handleClose,
    setResultAlert,
}: IEmpresaTransportistaEdit) => {
    const distpatch = useDispatch();

    const { empresaTransportistaSelected } = useAppSelector(
        (store) => store.empresaTransportista
    );



    const form = useForm({ defaultValues: empresaTransportistaSelected ?? {} });
    const { regions: { regions, provincies, communes } } = useAppSelector((state) => state.global);

    const { handleSubmit, watch } = form;

    const { regionId, provinciaId } = watch();

    const [inRequest, setInRequest] = useState<boolean>(false);

    const onSubmit = (data: IEmpresaTransportista) => {
        setInRequest(true);
        ApiEmpresaTransportista.PutUpdateEmpresaTransportista(data.id, data).then((resp: any) => {
            setInRequest(false);
            if (resp.data.success) {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "success",
                });

                distpatch(EmpresaTransportistaSaveListAction(resp.data.list));
            } else {
                setResultAlert({
                    message: resp.data?.message,
                    severity: "error",
                });
            }
        });
    };
    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <STextField
                        name="rut"
                        form={form}
                        type="text"
                        label="Rut"
                        mode="rut"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="descripcion"
                        form={form}
                        type="text"
                        label="Nombre"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <STextField
                        name="correo"
                        form={form}
                        type="text"
                        label="Correo"
                        mode="email"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SSelect
                        label="Vigente"
                        name="vigente"
                        form={form}
                        items={vigenteListSelect}
                        defaultValue={""}
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SSelect
                        label="Región"
                        name="regionId"
                        form={form}
                        items={regions}
                        textField="descripcion"
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SSelect
                        label="Provincia"
                        name="provinciaId"
                        form={form}
                        items={provincies}
                        textField="descripcion"
                        filterBy={{
                            value: regionId,
                            itemKey: "regionId",
                        }}
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SSelect
                        label="Comuna"
                        name="comunaId"
                        form={form}
                        items={communes}
                        textField="descripcion"
                        filterBy={{
                            value: provinciaId,
                            itemKey: "provinciaId",
                        }}
                        rules={{
                            required: "Este campo es requerido",
                        }}
                    />
                </Grid>
            </Grid>
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
            >
                Actualizar
            </LoadingButton>
            <Button fullWidth onClick={handleClose} variant="outlined">
                Cancel
            </Button>
        </Box>
    );
};
export default EmpresaTransportistaEdit;
