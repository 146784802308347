import { TSTextFieldMode } from "@intertal_types/TSTextField";
// @ts-ignore
import { rutFormat, rutValidate } from "rutfunctions";
import Form from "react-hook-form";
import { pEmail, pPatente, pRut, pNombreApellido, pDescripciones, pMarcaModelo, pNombreEmpresa } from "@helpers/patterns";
import { InputBaseProps } from "@mui/material";
import { ConvertToDecimal } from "@helpers/number";

export const TextFielRulesMode = (
    mode: TSTextFieldMode,
    rules?: Form.RegisterOptions
): Form.RegisterOptions | undefined => {
    if (mode === undefined || mode === "") return rules;
    switch (mode) {
        case "rut":
            return {
                pattern: {
                    value: pRut,
                    message: "Formato de Rut inválido",
                },
                ...rules,
                validate: {
                    validateRut: (value) => rutValidate(value) || "El rut no existe",
                    ...rules?.validate,
                },
                setValueAs: (fValue) => TextFieldFormatModeRut(fValue),
            };
        case "number":
        case "phone":
        case "thousandsSeparator":
            return {
                ...rules,
                setValueAs: (fValue) => TextFieldFormatModeNumber(fValue),
            };
        case "decimal":
            return {
                ...rules,
                setValueAs: (fValue) => TextFieldFormatModeDecimal(fValue),
            };
        case "email":
            return {
                pattern: {
                    value: pEmail,
                    message: "Debes ingresar un email válido",
                },
                ...rules,
            };
        case "patente":
            return {
                pattern: {
                    value: pPatente,
                    message: "Debes ingresar carácteres válidos",
                },
                setValueAs: (fValue) => TextFieldFormatModePatente(fValue),
                ...rules,
            };
        case "nombreyapellido":
            return {
                pattern: {
                    value: pNombreApellido,
                    message: "Debes ingresar un Nombre válido",
                },
                ...rules,
            };

        case "descripciones":
            return {
                pattern: {
                    value: pDescripciones,
                    message: "Debes ingresar carácteres válidos",
                },
                ...rules,
            };

        case "marcamodelo":
            return {
                pattern: {
                    value: pMarcaModelo,
                    message: "Debes ingresar carácteres válidos",
                },
                ...rules,
            };

        case "nombreempresa":
            return {
                pattern: {
                    value: pNombreEmpresa,
                    message: "Debes ingresar carácteres válidos",
                },
                ...rules,
            };

        default:
            return rules;
    }
};

export const TextFieldFormatMode = (mode: TSTextFieldMode, value: any) => {
    if (mode === undefined || mode === "") return value;
    switch (mode) {
        case "rut":
            return TextFieldFormatModeRut(value);

        default:
            return value;
    }
};

const TextFieldFormatModeRut = (value: string) => {
    if (value === undefined || value === "") return "";
    let newValue = rutFormat(value);
    if (newValue === "-") return "";
    return newValue;
};

const TextFieldFormatModeNumber = (value: string) => {
    if (value === undefined || value === "" || !!!value) return "";
    let formatedValue = value.toString().replace(/[^0-9]+/g, "");
    if (formatedValue === "") return "";
    return parseInt(formatedValue);
};

const TextFieldFormatModeDecimal = (value: string) => ConvertToDecimal(value);


const TextFieldFormatModePatente = (value: string) => {
    if (value === undefined || value === "") return "";
    return value.replace(/[^0-9A-Za-zñÑ]+/g, "").toUpperCase();
};


export const TextFieldInputVisibleValueMode = (
    value: string,
    mode: TSTextFieldMode,
) => {
    if (value === undefined || value === "") return "";
    switch (mode) {
        case "thousandsSeparator":
            return TextFieldFormatVisibleValueModeThousandsSeparator(value);
        default:
            return value;
    }
};


const TextFieldFormatVisibleValueModeThousandsSeparator = (value: string) => {
    if (value === undefined || value === "") return "";

    let reg = /\d{1,3}(?=(\d{3})+$)/g;
    return (value + '').replace(reg, '$&.');
};


export const TextFieldInputPropsMode = (
    mode: TSTextFieldMode,
    props: InputBaseProps["inputProps"]
) => {
    if (mode === undefined || mode === "") return props;
    switch (mode) {
        case "rut":
            return { ...props, maxLength: 12 };
        case "phone":
            return { ...props, maxLength: 9 };
        case "number":
        case "decimal":
            return { ...props, maxLength: 10 };
        case "thousandsSeparator":
            return { ...props, maxLength: 13 };
        case "patente":
            return { ...props, maxLength: 6 };
        default:
            return props;
    }
};
