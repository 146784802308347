import AcopladosCreate from "./partial/AcopladosCreate";
import AcopladosList from "./partial/AcopladosList";
const Acoplados = () => {

  return (
    <div className="container">
      <AcopladosCreate />
      <AcopladosList />
    </div>
  );
};
export default Acoplados;
