import {
    Collapse,
    Icon,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { INavigationItem } from "../../../../../ts/interfaces/globalParameters/INavigation";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LayoutPrivateSidebarNavigationSubItem from "./LayoutPrivateSidebarNavigationSubItem";

interface ILayoutPrivateSidebarNavigationItem extends INavigationItem {
    handleDrawerClose: () => any;
}

const LayoutPrivateSidebarNavigationItem = (
    item: ILayoutPrivateSidebarNavigationItem
) => {
    const [open2, setOpen2] = useState(false);

    const handleClick = () => {
        setOpen2(!open2);
    };

    if (item.redirect !== null && !!item.redirect)

        return (
            <Link
                to={`/${item.redirect}`}
                onClick={item.handleDrawerClose}
                style={{ textDecoration: "none" }}
            >
                <ListItem>
                    <ListItemIcon>
                        <Icon>{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText secondary={item.name} />
                </ListItem>
            </Link>
        );

    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText secondary={item.name} />
                {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            {/* DESPLEGAR */}
            <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {item.subItems?.map((subitem) => (
                        <LayoutPrivateSidebarNavigationSubItem
                            {...subitem}
                            handleDrawerClose={item.handleDrawerClose}
                            key={subitem.id}
                        />
                    ))}
                </List>
            </Collapse>
        </>
    );
};

export default LayoutPrivateSidebarNavigationItem;
