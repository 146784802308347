import { ITransporte } from "@interfaces/models/ITransporte";
import { ITrasporteBaseData } from "@interfaces/service/ITransporteService";
import { types } from "../types/types";

export const TransporteInitSelectAction = (data: ITrasporteBaseData) => ({
    type: types.TRANSPORTE_INIT,
    payload: data
})

export const TransporteSaveAction = (data: any) => ({
    type: types.TRANSPORTE_SAVE_LIST,
    payload: data
})

export const TransporteSelectToEditAction = (data: ITransporte | null) => ({
    type: types.TRANSPORTE_SELECT_TO_EDIT,
    payload: data
})
