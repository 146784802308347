import { IEmpresaDestino } from "@interfaces/models/IEmpresaDestino";
import { types } from "../types/types";

export const EmpresaDestinoSaveListAction = (data: IEmpresaDestino[]) => ({
    type: types.EMPRESADESTINO_SAVE_LIST,
    payload: data
})

export const EmpresaDestinoSelectToEditAction = (data: IEmpresaDestino | null) => ({
    type: types.EMPRESADESTINO_SELECT_TO_EDIT,
    payload: data
})
