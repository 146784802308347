
import { ICENTROCOSTOS_INIT_STATE } from '@interfaces/redux/IStorage';
import { types } from "../types/types";


const INIT_STATE: ICENTROCOSTOS_INIT_STATE = {
    centroCostosSelected: null,
    list: []
};

export const CentroCostosReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.CENTROCOSTOS_SAVE_LIST: {
            return {
                ...state,
                list: action.payload,
                centroCostosSelected: null
            };
        }
        case types.CENTROCOSTOS_SELECT_TO_EDIT: {
            return {
                ...state,
                centroCostosSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
