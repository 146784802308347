import { Chip, Grid, Typography } from "@mui/material";
import Form from "react-hook-form";
import SSelect from "@controls/inputs/SSelect";
import { CondPago, formaPago, TipoDespachoSII } from "@helpers/genericList";
import SDatePicker from "@controls/inputs/SDatePicker";

interface ITransportFormBilling {
    form: Form.UseFormReturn<any>;
}

const TransportFormBilling = (props: ITransportFormBilling) => {
    const { form } = props;

    return (
        <Grid container spacing={2} style={{ marginTop: "15px" }}>
            <Grid item xs={12}>
                <Typography component="h3" variant="h6">
                    <Chip label="2" color="error" size="small" /> Información facturación
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Forma de pago"
                    name="codigoFormaPago"
                    form={form}
                    items={formaPago}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Codiciones de pago"
                    name="codigoCondicionPago"
                    form={form}
                    items={CondPago}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SSelect
                    label="Tipo despacho"
                    name="codigoTipoDespacho"
                    form={form}
                    items={TipoDespachoSII}
                    defaultValue={""}
                    rules={{
                        required: "Este campo es requerido",
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <SDatePicker
                    form={form}
                    name="fechaPlanificada"
                    label="Fecha Planificada"
                />
            </Grid>
        </Grid>
    );
};

export default TransportFormBilling;
