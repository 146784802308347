import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from '@mui/material';
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@redux/store/store";
import STextField from "@controls/inputs/STextField";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { ICentroCostos } from "@interfaces/models/ICentroCostos";
import { ApiCentroCosto } from "@api/centroCostos";
import { CentroCostosSaveListAction } from "@redux/actions/centroCostosActions";

interface ITipoCargaEdit {
  handleClose: () => any;
  setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const TipoCargaEdit = ({ handleClose, setResultAlert }: ITipoCargaEdit) => {
  const distpatch = useDispatch();

  const { list,  centroCostosSelected } = useAppSelector((store) => store.centroCostos);

  const form = useForm({ defaultValues: centroCostosSelected ?? {} });

  const { handleSubmit } = form;

  const [inRequest, setInRequest] = useState<boolean>(false);

  const onSubmit = (data: ICentroCostos) => {
    setInRequest(true);
    ApiCentroCosto.Put(data.id, data).then((resp) => {
      setInRequest(false);
      if (resp.data.success) {
        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        let listFiltered = list?.filter(l => l.id !== resp.data.data.id) ?? [];
        listFiltered = [resp.data.data, ...listFiltered]
        
        distpatch(CentroCostosSaveListAction(listFiltered));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <STextField
            name="descripcion"
            form={form}
            type="text"
            label="Nombre de centro de costos"
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <STextField
            name="codigo"
            form={form}
            type="text"
            label="Código"
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={inRequest}
      >
        Actualizar
      </LoadingButton>
      <Button fullWidth onClick={handleClose} variant="outlined">
        Cancel
      </Button>
    </Box>
  );
};
export default TipoCargaEdit;
