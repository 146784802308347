import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApiVariedadCarga } from "@api/variedadcarga";
import { IVariedadCarga } from "@interfaces/models/IVariedadCarga";
import { VariedadCargaSaveListAction } from "@redux/actions/variedadesCargaActions";
import { useAppSelector } from "@redux/store/store";
import STextField from "@controls/inputs/STextField";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import SSelect from "@controls/inputs/SSelect";
interface IVariedadCargaEdit {
  handleClose: () => any;
  setResultAlert: (newState: TSSnackbarAlertState) => any;
}

const VariedadCargaEdit = ({ handleClose, setResultAlert }: IVariedadCargaEdit) => {
  const distpatch = useDispatch();

  const { variedadCargaSelected } = useAppSelector((store) => store.variedadesCarga);

  const {selectBaseData: { tiposCarga }} = useAppSelector((storage) => storage.variedadesCarga);

  const form = useForm({ defaultValues: variedadCargaSelected ?? {} });

  const { handleSubmit } = form;

  const [inRequest, setInRequest] = useState<boolean>(false);

  const onSubmit = (data: IVariedadCarga) => {
    setInRequest(true);
    ApiVariedadCarga.PutVariedadCarga(data.id, data).then((resp: any) => {
      setInRequest(false);
      if (resp.data.success) {
        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(VariedadCargaSaveListAction(resp.data.list));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <STextField
            name="descripcion"
            form={form}
            type="text"
            label="Nombre de Variedad Carga"
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12}>
              <SSelect
                label="Tipo de carga"
                name="tipocargaId"
                form={form}
                items={tiposCarga}
                defaultValue={""}
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
      </Grid>
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        loading={inRequest}
      >
        Actualizar
      </LoadingButton>
      <Button fullWidth onClick={handleClose} variant="outlined">
        Cancel
      </Button>
    </Box>
  );
};
export default VariedadCargaEdit;
