import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";

function LoginFooter() {
	return (
		<>
			<Box
				sx={{
					backgroundColor: "#28B463",
					color: "white"
				}}
			>
				<Typography
					variant="body2"
					color="text.white"
					align="center"
					sx={{ mt: 5 }}
				>
					{"Copyright © "}
					<Link color="inherit" href="https://www.soseragro.cl/">
						Sistema agropecuaria Santa Isabel
					</Link>{" "}
					{new Date().getFullYear()}
					{"."}
				</Typography>
			</Box>
		</>
	);
}

export default LoginFooter;
