import { LoadingButton } from "@mui/lab";
import { Container, Grid, InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApiAcoplado } from "@api/acoplado";

import STextField from "@controls/inputs/STextField";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { AcopladosSaveListAction } from '@redux/actions/acopladosActions';



const AcopladosCreate = () => {
  const distpatch = useDispatch();

  const form = useForm({ mode: "onChange" });

  const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});
  const [inRequest, setInRequest] = useState<boolean>(false);

  const { handleSubmit } = form;

  const onSubmit = (data: any) => {
    setInRequest(true);
    ApiAcoplado.PostAcoplado(data).then((resp: any) => {
      setInRequest(false);
      if (resp.data.success) {
        form.reset({});

        setResultAlert({
          message: resp.data?.message,
          severity: "success",
        });

        distpatch(AcopladosSaveListAction(resp.data.list));
      } else {
        setResultAlert({
          message: resp.data?.message,
          severity: "error",
        });
      }
    });
  };

  return (
    <Container component="main">
      <Box>
        <Typography component="h1" variant="h4">
          Formulario de Acoplados
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <STextField
                name="descripcion"
                form={form}
                type="text"
                label="Nombre acoplado"
                mode="marcamodelo"
                rules={{
                  required: "Este campo es requerido",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <STextField
                name="patente"
                form={form}
                type="text"
                label="Patente"
                mode="patente"
                rules={{
                  required: "Este campo es requerido",
                  minLength: {
                    value: 6,
                    message: "Patente debe tener al menos 6 caracteres",
                  },
                  maxLength: {
                    value: 6,
                    message: "Supera el máximo permitido",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <STextField
                name="tara"
                form={form}
                mode="number"
                label="Tara"
                rules={{
                  required: "Este campo es requerido",
                  maxLength: {
                    value: 4,
                    message: "Supera el máximo permitido",
                  },
                }}
                
                InputProps={{
                  endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={inRequest}
              >
                Guardar acoplado
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
    </Container>
  );
};
export default AcopladosCreate;
