import { Axios } from "../config/axios"
import { ITransporte } from "../interfaces/models/ITransporte";

const endpoint = "pesaje/"

const PostPesaje = async (transporte: ITransporte) => {
    return await Axios().post(endpoint, transporte);
}



export const ApiPesaje = {
    PostPesaje,
}
