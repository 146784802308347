import { useEffect, useState } from "react";
import { ApiTransporte } from "@api/transporte";
import LoadingPage from "@controls/util/LoadingPage";
import { TransporteInitSelectAction } from "@redux/actions/transporteActions";
import { useDispatch } from "react-redux";
import PesajeCreate from "./partial/PesajeCreate";

const Pesaje = () => {
    const [loaded, setLoaded] = useState(false);

    const distpatch = useDispatch();

    useEffect(() => {
        ApiTransporte.GetAllData().then((resp: any) => {
            distpatch(TransporteInitSelectAction(resp.data));
            setLoaded(true);
        });

        // eslint-disable-next-line
    }, []);

    if (!loaded) return <LoadingPage />;
    return <div className="container">
        <PesajeCreate/>
    </div>;
};

export default Pesaje;
