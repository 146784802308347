import { IVARIEDADCARGA_INIT_STATE } from "@interfaces/redux/IStorage";
import { IVariedadCarga } from "@interfaces/models/IVariedadCarga";
import { types } from "../types/types";

export const VariedadCargaInitAction = (data: IVARIEDADCARGA_INIT_STATE) => ({
    type: types.VARIEDADCARGA_INIT,
    payload: data
})

export const VariedadCargaSaveListAction = (data: IVariedadCarga[]) => ({
    type: types.VARIEDADCARGA_SAVE_LIST,
    payload: data
})

export const VariedadCargaSelectToEditAction = (data: IVariedadCarga | null) => ({
    type: types.VARIEDADCARGA_SELECT_TO_EDIT,
    payload: data
})
