import { ICONDUCTORES_INIT_STATE } from "@interfaces/redux/IStorage";
import { types } from "../types/types";



const INIT_STATE: ICONDUCTORES_INIT_STATE = {
    conductorSelected: null,
    list: []
};

export const ConductoresReducers = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case types.CONDUCTORES_SAVE_LIST: {
            return {
                ...state,
                list: action.payload,
                conductorSelected: null
            };
        }
        case types.CONDUCTORES_SELECT_TO_EDIT: {
            return {
                ...state,
                conductorSelected: action.payload,
            };
        }
        default:
            return state;
    }
};
