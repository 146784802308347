import { IAcoplado } from "@interfaces/models/IAcoplado";
import { types } from "../types/types";

export const AcopladosSaveListAction = (data: IAcoplado[]) => ({
    type: types.ACOPLADOS_SAVE_LIST,
    payload: data
})

export const AcopladosSelectToEditAction = (data: IAcoplado | null) => ({
    type: types.ACOPLADOS_SELECT_TO_EDIT,
    payload: data
})
