import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ICamion } from "@interfaces/models/ICamion";
import { CamionesSaveListAction, CamionesSelectToEditAction } from "@redux/actions/camionActions";
import { useAppSelector } from "@redux/store/store";
import SDataGrid from "@controls/tables/SDataGrid";
import CamionesEdit from "./CamionesEdit";
import SSnackBarAlert from "@controls/modal/SSnackbarAlert";
import { TSSnackbarAlertState } from "@controls/modal/types/TSSnackbarAlert";
import { ApiCamion } from "@api/camion";

const CamionList = () => {
    const distpatch = useDispatch();

    const { list, camionSelected } = useAppSelector((state) => state.camiones);
    const [resultAlert, setResultAlert] = useState<TSSnackbarAlertState>({});

    const Edit = (selected: ICamion) => {
        distpatch(CamionesSelectToEditAction(selected));
    }

    const handleClose = () => {
        distpatch(CamionesSelectToEditAction(null));
    };

    const columns: GridColDef[] = [
        {
            field: "patente",
            headerName: "Patente",
            flex: 3
        },
        {
            field: "camionModeloDesc",
            headerName: "Modelo",
            flex: 3
        },
        {
            field: "camionMarcaDesc",
            headerName: "Marca",
            flex: 3
        },
        {
            field: "anio",
            headerName: "Año",
            flex: 3
        },
        {
            field: "tara",
            headerName: "Tara",
            flex: 3
        },
        {
            field: "vigente",
            headerName: "Vigente",
            flex: 3,
            renderCell: (params: GridValueGetterParams) => (
                params.row.vigente ? "Sí": "No"
            ),
        },
        {
            field: "seguro",
            headerName: "Seguro",
            flex: 3,
            renderCell: (params: GridValueGetterParams) => (
                params.row.seguro ? "Sí": "No"
            ),
        },
        {
            field: "camionCodigoMopDesc",
            headerName: "Codigo Mop",
            flex: 3
        },
        {
            field: "id",
            headerName: "Acción",
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: GridValueGetterParams) => (
                <Button color="warning" onClick={() => Edit(params.row)}>Editar</Button>
            ),
        },
    ];

    useEffect(() => {
        ApiCamion.GetCamiones().then((resp: any) => {
            distpatch(CamionesSaveListAction(resp.data));
        });
        // eslint-disable-next-line
    }, []);

    if (!list) return <></>;

    return (
        <Box sx={{ height: 400, width: "95%", ml: "20px", mr: "20px" }}>
            <SDataGrid rows={list} columns={columns} disableSelectionOnClick />

            <Dialog open={!!camionSelected} onClose={handleClose} >
                <DialogTitle>Editar Camión</DialogTitle>
                <DialogContent>
                    <CamionesEdit
                        handleClose={handleClose} setResultAlert={setResultAlert} />
                </DialogContent>
            </Dialog>

            <SSnackBarAlert state={resultAlert} setState={setResultAlert} />
        </Box>
    );
};

export default CamionList;
