import LoadingPage from "@controls/util/LoadingPage";
import { VariedadCargaInitAction } from "@redux/actions/variedadesCargaActions";
import VariedadesCargaCreate from "./partial/VariedadesCargaCreate";
import VariedadesCargaList from "./partial/VariedadesCargaList";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiVariedadCarga } from "@api/variedadcarga";
const VariedadesCarga = () => {

    const [loaded, setLoaded] = useState(false);

    const distpatch = useDispatch();

    useEffect(() => {
        ApiVariedadCarga.GetAllData().then((resp: any) => {
            distpatch(VariedadCargaInitAction(resp.data.data));
            setLoaded(true);
        });
        // eslint-disable-next-line
    }, []);

    if (!loaded) return <LoadingPage/>;



    return (
        <div className="container">
            <VariedadesCargaCreate />
            <VariedadesCargaList />
        </div>
    );
};
export default VariedadesCarga;
