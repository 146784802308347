import { ILogin } from "@interfaces/auth/ILogin";
import { types } from "../types/types";
import { IGlobalParameters } from "@interfaces/globalParameters/IGlobalParameters";

export const GlobalIniting = () => ({
    type: types.GLOBAL_INITING,
})

export const GlobalLogin = (data: ILogin) => ({
    type: types.GLOBAL_LOGIN,
    payload: data
})

export const GlobalInit = (data: any) => ({
    type: types.GLOBAL_INIT,
    payload: data
})

export const GlobalParameters = (data: IGlobalParameters) => ({
    type: types.GLOBAL_PARAMETERS,
    payload: data
}) 

export const GlobalLogout = () => ({
    type: types.GLOBAL_LOGOUT
})
