import axios, { AxiosInstance } from "axios";
import { store } from "../redux/store/store";
import { ApiUrl } from "./env";

export const Axios = (): AxiosInstance => {
    const {
        authentificatedUser: { token },
    } = store.getState().global;

    const axiosCreated = axios.create({
        baseURL: ApiUrl(),
        headers: { Authorization: `Bearer ${token}` },
    });
    return axiosCreated;
};

export const AxiosFormData = (): AxiosInstance => {
    const {
        authentificatedUser: { token },
    } = store.getState().global;
    
    const axiosCreated = axios.create({
        baseURL: ApiUrl(),
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
    });

    return axiosCreated;
};
