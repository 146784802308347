import { IUsuario } from "@interfaces/models/IUsuario";
import { Axios } from "../config/axios"

const endpoint = "auth/"



const PostLogin = async (usuario: IUsuario) => {
    return await Axios().post(endpoint, usuario);
}


export const ApiAuth = {
    PostLogin,
}